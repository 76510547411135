
import { computed, defineComponent, Ref, ref } from 'vue';
import CalendarLink from './components/CalendarLink.vue';
import HierarchicalSelect from './components/HierarchicalSelect.vue';
import { regions } from './timezones';
import { cities } from './cities';
import { Nullable } from './types/nullable';

export default defineComponent({
	name: 'App',
	components: {
		CalendarLink,
		HierarchicalSelect
	},
	setup: () => {
		const timezone: Ref<Nullable<string>> = ref<Nullable<string>>(null);
		const lat: Ref<Nullable<number>> = ref<Nullable<number>>(null);
		const long: Ref<Nullable<number>> = ref<Nullable<number>>(null);
		const title: Ref<Nullable<string>> = ref<Nullable<string>>(null);

		const isLinkValid = computed(() => {
			return (
				lat.value !== null &&
				!isNaN(lat.value) &&
				long.value !== null &&
				!isNaN(long.value) &&
				!!title.value &&
				!!timezone.value
			);
		});

		function onTimezoneSelected(value: Nullable<string>) {
			timezone.value = value;
		}
		return {
			onTimezoneSelected,
			isLinkValid,
			timezone,
			lat,
			long,
			title,
			cities,
			regions
		};
	}
});
