import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01c6de5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "formattedLocation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      href: `webcal://sun-calendar.darrylh.ca/sun/?lat=${_ctx.lat}&long=${_ctx.long}&timezone=${_ctx.timezone}&location=${_ctx.title}`
    }, _toDisplayString(_ctx.title), 9, _hoisted_1),
    _createElementVNode("span", _hoisted_2, "(" + _toDisplayString(_ctx.formattedLocation) + ")", 1)
  ], 64))
}