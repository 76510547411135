export interface ICity {
	name: string;
	lat: number;
	long: number;
	timezone: string;
}

export const cities: ICity[] = [
	{
		name: 'Vancouver, Canada',
		lat: 49.3,
		long: -123.2,
		timezone: 'America/Vancouver'
	},
	{
		name: 'Reykjavík, Iceland',
		lat: 64.15,
		long: -21.94,
		timezone: 'Atlantic/Reykjavik'
	},
	{
		name: 'Longyearbyen, Norway',
		lat: 78.22,
		long: 15.63,
		timezone: 'Arctic/Longyearbyen'
	}
];
