
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
import { ITimeZone } from '@/timezones';
import { Nullable } from '@/types/nullable';

export default defineComponent({
	name: 'HierarchicalSelect',
	props: {
		options: { type: Array, required: true }
	},
	emits: ['selected'],
	setup(props, context) {
		const selectedValue: Ref<Nullable<ITimeZone>> =
			ref<Nullable<ITimeZone>>(null);

		const subZones: ComputedRef<Nullable<ITimeZone[]>> = computed(() => {
			return selectedValue.value?.hasChildren === true
				? selectedValue.value.subZones
				: null;
		});

		function onSelectChanged() {
			if (selectedValue.value?.hasChildren === true) {
				context.emit('selected', null);
			} else {
				context.emit('selected', selectedValue.value?.name || null);
			}
		}

		function onChildSelected(value: string) {
			if (value && selectedValue.value) {
				context.emit(
					'selected',
					`${selectedValue.value.name}/${value}`
				);
			}
		}

		return {
			selectedValue,
			subZones,
			onSelectChanged,
			onChildSelected
		};
	}
});
