
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'CalendarLink',
	props: {
		lat: { type: Number, required: true },
		long: { type: Number, required: true },
		timezone: { type: String, required: true },
		title: { type: String, required: true }
	},
	setup(props) {
		const formattedLocation = computed(() => {
			const lat: number = props.lat;
			const long: number = props.long;
			const latHemi: string = lat < 0 ? 'S' : 'N';
			const longHemi: string = long > 0 ? 'E' : 'W';

			return `${Math.abs(lat)}°${latHemi}, ${Math.abs(long)}°${longHemi}`;
		});
		return {
			formattedLocation
		};
	}
});
