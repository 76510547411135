export interface ITimeZoneLeaf {
	name: string;
	displayName?: string;
	hasChildren: false;
	fullName: string;
	countryCode: string;
	latitude: number;
	longitude: number;
	comments?: string;
}

export interface ITimeZoneNode {
	name: string;
	displayName?: string;
	hasChildren: true;
	subZones: ITimeZone[];
}

export type ITimeZone = ITimeZoneNode | ITimeZoneLeaf;

export const regions: ITimeZone[] = [
	{
		name: 'Africa',
		hasChildren: true,
		subZones: [
			{
				name: 'Abidjan',
				hasChildren: false,
				fullName: 'Africa/Abidjan',
				countryCode: 'CI',
				latitude: 5.316659999999999,
				longitude: -4.03334000000001
			},
			{
				name: 'Accra',
				hasChildren: false,
				fullName: 'Africa/Accra',
				countryCode: 'GH',
				latitude: 5.549999999999997,
				longitude: -0.21666999999999348
			},
			{
				name: 'Addis_Ababa',
				displayName: 'Addis Ababa',
				hasChildren: false,
				fullName: 'Africa/Addis_Ababa',
				countryCode: 'ET',
				latitude: 9.033330000000007,
				longitude: 38.69999999999999
			},
			{
				name: 'Algiers',
				hasChildren: false,
				fullName: 'Africa/Algiers',
				countryCode: 'DZ',
				latitude: 36.78333000000001,
				longitude: 3.0500000000000114
			},
			{
				name: 'Asmara',
				hasChildren: false,
				fullName: 'Africa/Asmara',
				countryCode: 'ER',
				latitude: 15.333330000000004,
				longitude: 38.88333
			},
			{
				name: 'Bamako',
				hasChildren: false,
				fullName: 'Africa/Bamako',
				countryCode: 'ML',
				latitude: 12.650000000000006,
				longitude: -8
			},
			{
				name: 'Bangui',
				hasChildren: false,
				fullName: 'Africa/Bangui',
				countryCode: 'CF',
				latitude: 4.366659999999996,
				longitude: 18.58332999999999
			},
			{
				name: 'Banjul',
				hasChildren: false,
				fullName: 'Africa/Banjul',
				countryCode: 'GM',
				latitude: 13.466660000000005,
				longitude: -16.650000000000006
			},
			{
				name: 'Bissau',
				hasChildren: false,
				fullName: 'Africa/Bissau',
				countryCode: 'GW',
				latitude: 11.849999999999994,
				longitude: -15.583339999999993
			},
			{
				name: 'Blantyre',
				hasChildren: false,
				fullName: 'Africa/Blantyre',
				countryCode: 'MW',
				latitude: -15.783339999999995,
				longitude: 35
			},
			{
				name: 'Brazzaville',
				hasChildren: false,
				fullName: 'Africa/Brazzaville',
				countryCode: 'CG',
				latitude: -4.266670000000005,
				longitude: 15.283330000000007
			},
			{
				name: 'Bujumbura',
				hasChildren: false,
				fullName: 'Africa/Bujumbura',
				countryCode: 'BI',
				latitude: -3.383340000000004,
				longitude: 29.366659999999996
			},
			{
				name: 'Cairo',
				hasChildren: false,
				fullName: 'Africa/Cairo',
				countryCode: 'EG',
				latitude: 30.049999999999997,
				longitude: 31.25
			},
			{
				name: 'Casablanca',
				hasChildren: false,
				fullName: 'Africa/Casablanca',
				countryCode: 'MA',
				latitude: 33.650000000000006,
				longitude: -7.583339999999993
			},
			{
				name: 'Ceuta',
				hasChildren: false,
				fullName: 'Africa/Ceuta',
				countryCode: 'ES',
				latitude: 35.88333,
				longitude: -5.316669999999988,
				comments: 'Ceuta, Melilla'
			},
			{
				name: 'Conakry',
				hasChildren: false,
				fullName: 'Africa/Conakry',
				countryCode: 'GN',
				latitude: 9.516660000000002,
				longitude: -13.716669999999993
			},
			{
				name: 'Dakar',
				hasChildren: false,
				fullName: 'Africa/Dakar',
				countryCode: 'SN',
				latitude: 14.666659999999993,
				longitude: -17.433339999999987
			},
			{
				name: 'Dar_es_Salaam',
				displayName: 'Dar es Salaam',
				hasChildren: false,
				fullName: 'Africa/Dar_es_Salaam',
				countryCode: 'TZ',
				latitude: -6.799999999999997,
				longitude: 39.28333000000001
			},
			{
				name: 'Djibouti',
				hasChildren: false,
				fullName: 'Africa/Djibouti',
				countryCode: 'DJ',
				latitude: 11.599999999999994,
				longitude: 43.150000000000006
			},
			{
				name: 'Douala',
				hasChildren: false,
				fullName: 'Africa/Douala',
				countryCode: 'CM',
				latitude: 4.049999999999997,
				longitude: 9.699999999999989
			},
			{
				name: 'El_Aaiun',
				displayName: 'El Aaiun',
				hasChildren: false,
				fullName: 'Africa/El_Aaiun',
				countryCode: 'EH',
				latitude: 27.150000000000006,
				longitude: -13.199999999999989
			},
			{
				name: 'Freetown',
				hasChildren: false,
				fullName: 'Africa/Freetown',
				countryCode: 'SL',
				latitude: 8.5,
				longitude: -13.25
			},
			{
				name: 'Gaborone',
				hasChildren: false,
				fullName: 'Africa/Gaborone',
				countryCode: 'BW',
				latitude: -24.650009999999995,
				longitude: 25.916660000000007
			},
			{
				name: 'Harare',
				hasChildren: false,
				fullName: 'Africa/Harare',
				countryCode: 'ZW',
				latitude: -17.833340000000007,
				longitude: 31.05000000000001
			},
			{
				name: 'Johannesburg',
				hasChildren: false,
				fullName: 'Africa/Johannesburg',
				countryCode: 'ZA',
				latitude: -26.25,
				longitude: 28
			},
			{
				name: 'Juba',
				hasChildren: false,
				fullName: 'Africa/Juba',
				countryCode: 'SS',
				latitude: 4.849999999999994,
				longitude: 31.616659999999996
			},
			{
				name: 'Kampala',
				hasChildren: false,
				fullName: 'Africa/Kampala',
				countryCode: 'UG',
				latitude: 0.31665999999999883,
				longitude: 32.41666000000001
			},
			{
				name: 'Khartoum',
				hasChildren: false,
				fullName: 'Africa/Khartoum',
				countryCode: 'SD',
				latitude: 15.599999999999994,
				longitude: 32.53333000000001
			},
			{
				name: 'Kigali',
				hasChildren: false,
				fullName: 'Africa/Kigali',
				countryCode: 'RW',
				latitude: -1.9500000000000028,
				longitude: 30.066660000000013
			},
			{
				name: 'Kinshasa',
				hasChildren: false,
				fullName: 'Africa/Kinshasa',
				countryCode: 'CD',
				latitude: -4.299999999999997,
				longitude: 15.300000000000011,
				comments: 'Dem. Rep. of Congo (west)'
			},
			{
				name: 'Lagos',
				hasChildren: false,
				fullName: 'Africa/Lagos',
				countryCode: 'NG',
				latitude: 6.450000000000003,
				longitude: 3.4000000000000057
			},
			{
				name: 'Libreville',
				hasChildren: false,
				fullName: 'Africa/Libreville',
				countryCode: 'GA',
				latitude: 0.38333000000000084,
				longitude: 9.449999999999989
			},
			{
				name: 'Lome',
				hasChildren: false,
				fullName: 'Africa/Lome',
				countryCode: 'TG',
				latitude: 6.133330000000001,
				longitude: 1.2166599999999903
			},
			{
				name: 'Luanda',
				hasChildren: false,
				fullName: 'Africa/Luanda',
				countryCode: 'AO',
				latitude: -8.799999999999997,
				longitude: 13.233329999999995
			},
			{
				name: 'Lubumbashi',
				hasChildren: false,
				fullName: 'Africa/Lubumbashi',
				countryCode: 'CD',
				latitude: -11.666669999999996,
				longitude: 27.46665999999999,
				comments: 'Dem. Rep. of Congo (east)'
			},
			{
				name: 'Lusaka',
				hasChildren: false,
				fullName: 'Africa/Lusaka',
				countryCode: 'ZM',
				latitude: -15.416669999999996,
				longitude: 28.283330000000007
			},
			{
				name: 'Malabo',
				hasChildren: false,
				fullName: 'Africa/Malabo',
				countryCode: 'GQ',
				latitude: 3.75,
				longitude: 8.783330000000007
			},
			{
				name: 'Maputo',
				hasChildren: false,
				fullName: 'Africa/Maputo',
				countryCode: 'MZ',
				latitude: -25.966669999999993,
				longitude: 32.58332999999999
			},
			{
				name: 'Maseru',
				hasChildren: false,
				fullName: 'Africa/Maseru',
				countryCode: 'LS',
				latitude: -29.46667,
				longitude: 27.5
			},
			{
				name: 'Mbabane',
				hasChildren: false,
				fullName: 'Africa/Mbabane',
				countryCode: 'SZ',
				latitude: -26.299999999999997,
				longitude: 31.099999999999994
			},
			{
				name: 'Mogadishu',
				hasChildren: false,
				fullName: 'Africa/Mogadishu',
				countryCode: 'SO',
				latitude: 2.066659999999999,
				longitude: 45.366659999999996
			},
			{
				name: 'Monrovia',
				hasChildren: false,
				fullName: 'Africa/Monrovia',
				countryCode: 'LR',
				latitude: 6.299999999999997,
				longitude: -10.78334000000001
			},
			{
				name: 'Nairobi',
				hasChildren: false,
				fullName: 'Africa/Nairobi',
				countryCode: 'KE',
				latitude: -1.2833399999999955,
				longitude: 36.81666000000001
			},
			{
				name: 'Ndjamena',
				hasChildren: false,
				fullName: 'Africa/Ndjamena',
				countryCode: 'TD',
				latitude: 12.116659999999996,
				longitude: 15.050000000000011
			},
			{
				name: 'Niamey',
				hasChildren: false,
				fullName: 'Africa/Niamey',
				countryCode: 'NE',
				latitude: 13.516660000000002,
				longitude: 2.116659999999996
			},
			{
				name: 'Nouakchott',
				hasChildren: false,
				fullName: 'Africa/Nouakchott',
				countryCode: 'MR',
				latitude: 18.099999999999994,
				longitude: -15.949999999999989
			},
			{
				name: 'Ouagadougou',
				hasChildren: false,
				fullName: 'Africa/Ouagadougou',
				countryCode: 'BF',
				latitude: 12.366659999999996,
				longitude: -1.5166700000000048
			},
			{
				name: 'Porto-Novo',
				hasChildren: false,
				fullName: 'Africa/Porto-Novo',
				countryCode: 'BJ',
				latitude: 6.483329999999995,
				longitude: 2.616659999999996
			},
			{
				name: 'Sao_Tome',
				displayName: 'Sao Tome',
				hasChildren: false,
				fullName: 'Africa/Sao_Tome',
				countryCode: 'ST',
				latitude: 0.3333300000000037,
				longitude: 6.733329999999995
			},
			{
				name: 'Tripoli',
				hasChildren: false,
				fullName: 'Africa/Tripoli',
				countryCode: 'LY',
				latitude: 32.900000000000006,
				longitude: 13.183330000000012
			},
			{
				name: 'Tunis',
				hasChildren: false,
				fullName: 'Africa/Tunis',
				countryCode: 'TN',
				latitude: 36.8,
				longitude: 10.183330000000012
			},
			{
				name: 'Windhoek',
				hasChildren: false,
				fullName: 'Africa/Windhoek',
				countryCode: 'NA',
				latitude: -22.566670000000002,
				longitude: 17.099999999999994
			}
		]
	},
	{
		name: 'America',
		hasChildren: true,
		subZones: [
			{
				name: 'Adak',
				hasChildren: false,
				fullName: 'America/Adak',
				countryCode: 'US',
				latitude: 51.879999999999995,
				longitude: -176.65806,
				comments: 'Aleutian Islands'
			},
			{
				name: 'Anchorage',
				hasChildren: false,
				fullName: 'America/Anchorage',
				countryCode: 'US',
				latitude: 61.218050000000005,
				longitude: -149.90028,
				comments: 'Alaska (most areas)'
			},
			{
				name: 'Anguilla',
				hasChildren: false,
				fullName: 'America/Anguilla',
				countryCode: 'AI',
				latitude: 18.200000000000003,
				longitude: -63.06667
			},
			{
				name: 'Antigua',
				hasChildren: false,
				fullName: 'America/Antigua',
				countryCode: 'AG',
				latitude: 17.049999999999997,
				longitude: -61.8
			},
			{
				name: 'Araguaina',
				hasChildren: false,
				fullName: 'America/Araguaina',
				countryCode: 'BR',
				latitude: -7.200000000000003,
				longitude: -48.19999999999999,
				comments: 'Tocantins'
			},
			{
				name: 'Argentina',
				hasChildren: true,
				subZones: [
					{
						name: 'Buenos_Aires',
						displayName: 'Buenos Aires',
						hasChildren: false,
						fullName: 'America/Argentina/Buenos_Aires',
						countryCode: 'AR',
						latitude: -34.6,
						longitude: -58.45,
						comments: 'Buenos Aires (BA, CF)'
					},
					{
						name: 'Catamarca',
						hasChildren: false,
						fullName: 'America/Argentina/Catamarca',
						countryCode: 'AR',
						latitude: -28.46667,
						longitude: -65.78334,
						comments: 'Catamarca (CT); Chubut (CH)'
					},
					{
						name: 'Cordoba',
						hasChildren: false,
						fullName: 'America/Argentina/Cordoba',
						countryCode: 'AR',
						latitude: -31.4,
						longitude: -64.18334,
						comments:
							'Argentina (most areas: CB, CC, CN, ER, FM, MN, SE, SF)'
					},
					{
						name: 'Jujuy',
						hasChildren: false,
						fullName: 'America/Argentina/Jujuy',
						countryCode: 'AR',
						latitude: -24.18334,
						longitude: -65.3,
						comments: 'Jujuy (JY)'
					},
					{
						name: 'La_Rioja',
						displayName: 'La Rioja',
						hasChildren: false,
						fullName: 'America/Argentina/La_Rioja',
						countryCode: 'AR',
						latitude: -29.43334,
						longitude: -66.85,
						comments: 'La Rioja (LR)'
					},
					{
						name: 'Mendoza',
						hasChildren: false,
						fullName: 'America/Argentina/Mendoza',
						countryCode: 'AR',
						latitude: -32.88334,
						longitude: -68.81667,
						comments: 'Mendoza (MZ)'
					},
					{
						name: 'Rio_Gallegos',
						displayName: 'Rio Gallegos',
						hasChildren: false,
						fullName: 'America/Argentina/Rio_Gallegos',
						countryCode: 'AR',
						latitude: -51.63334,
						longitude: -69.21667,
						comments: 'Santa Cruz (SC)'
					},
					{
						name: 'Salta',
						hasChildren: false,
						fullName: 'America/Argentina/Salta',
						countryCode: 'AR',
						latitude: -24.783339999999995,
						longitude: -65.41667,
						comments: 'Salta (SA, LP, NQ, RN)'
					},
					{
						name: 'San_Juan',
						displayName: 'San Juan',
						hasChildren: false,
						fullName: 'America/Argentina/San_Juan',
						countryCode: 'AR',
						latitude: -31.533340000000003,
						longitude: -68.51667,
						comments: 'San Juan (SJ)'
					},
					{
						name: 'San_Luis',
						displayName: 'San Luis',
						hasChildren: false,
						fullName: 'America/Argentina/San_Luis',
						countryCode: 'AR',
						latitude: -33.31667,
						longitude: -66.35,
						comments: 'San Luis (SL)'
					},
					{
						name: 'Tucuman',
						hasChildren: false,
						fullName: 'America/Argentina/Tucuman',
						countryCode: 'AR',
						latitude: -26.816670000000002,
						longitude: -65.21667,
						comments: 'Tucuman (TM)'
					},
					{
						name: 'Ushuaia',
						hasChildren: false,
						fullName: 'America/Argentina/Ushuaia',
						countryCode: 'AR',
						latitude: -54.8,
						longitude: -68.3,
						comments: 'Tierra del Fuego (TF)'
					}
				]
			},
			{
				name: 'Aruba',
				hasChildren: false,
				fullName: 'America/Aruba',
				countryCode: 'AW',
				latitude: 12.5,
				longitude: -69.96667
			},
			{
				name: 'Asuncion',
				hasChildren: false,
				fullName: 'America/Asuncion',
				countryCode: 'PY',
				latitude: -25.266670000000005,
				longitude: -57.666669999999996
			},
			{
				name: 'Atikokan',
				hasChildren: false,
				fullName: 'America/Atikokan',
				countryCode: 'CA',
				latitude: 48.758610000000004,
				longitude: -91.62167,
				comments: 'EST - ON (Atikokan); NU (Coral H)'
			},
			{
				name: 'Bahia',
				hasChildren: false,
				fullName: 'America/Bahia',
				countryCode: 'BR',
				latitude: -12.983339999999998,
				longitude: -38.516670000000005,
				comments: 'Bahia'
			},
			{
				name: 'Bahia_Banderas',
				displayName: 'Bahia Banderas',
				hasChildren: false,
				fullName: 'America/Bahia_Banderas',
				countryCode: 'MX',
				latitude: 20.799999999999997,
				longitude: -105.25,
				comments: 'Central Time - Bahia de Banderas'
			},
			{
				name: 'Barbados',
				hasChildren: false,
				fullName: 'America/Barbados',
				countryCode: 'BB',
				latitude: 13.099999999999994,
				longitude: -59.61667
			},
			{
				name: 'Belem',
				hasChildren: false,
				fullName: 'America/Belem',
				countryCode: 'BR',
				latitude: -1.4500000000000028,
				longitude: -48.48334,
				comments: 'Para (east); Amapa'
			},
			{
				name: 'Belize',
				hasChildren: false,
				fullName: 'America/Belize',
				countryCode: 'BZ',
				latitude: 17.5,
				longitude: -88.2
			},
			{
				name: 'Blanc-Sablon',
				hasChildren: false,
				fullName: 'America/Blanc-Sablon',
				countryCode: 'CA',
				latitude: 51.41666000000001,
				longitude: -57.11667,
				comments: 'AST - QC (Lower North Shore)'
			},
			{
				name: 'Boa_Vista',
				displayName: 'Boa Vista',
				hasChildren: false,
				fullName: 'America/Boa_Vista',
				countryCode: 'BR',
				latitude: 2.816659999999999,
				longitude: -60.666669999999996,
				comments: 'Roraima'
			},
			{
				name: 'Bogota',
				hasChildren: false,
				fullName: 'America/Bogota',
				countryCode: 'CO',
				latitude: 4.599999999999994,
				longitude: -74.08334
			},
			{
				name: 'Boise',
				hasChildren: false,
				fullName: 'America/Boise',
				countryCode: 'US',
				latitude: 43.613609999999994,
				longitude: -116.2025,
				comments: 'Mountain - ID (south); OR (east)'
			},
			{
				name: 'Cambridge_Bay',
				displayName: 'Cambridge Bay',
				hasChildren: false,
				fullName: 'America/Cambridge_Bay',
				countryCode: 'CA',
				latitude: 69.11388,
				longitude: -105.05278,
				comments: 'Mountain - NU (west)'
			},
			{
				name: 'Campo_Grande',
				displayName: 'Campo Grande',
				hasChildren: false,
				fullName: 'America/Campo_Grande',
				countryCode: 'BR',
				latitude: -20.450000000000003,
				longitude: -54.61667,
				comments: 'Mato Grosso do Sul'
			},
			{
				name: 'Cancun',
				hasChildren: false,
				fullName: 'America/Cancun',
				countryCode: 'MX',
				latitude: 21.083330000000004,
				longitude: -86.76667,
				comments: 'Eastern Standard Time - Quintana Roo'
			},
			{
				name: 'Caracas',
				hasChildren: false,
				fullName: 'America/Caracas',
				countryCode: 'VE',
				latitude: 10.5,
				longitude: -66.93334
			},
			{
				name: 'Cayenne',
				hasChildren: false,
				fullName: 'America/Cayenne',
				countryCode: 'GF',
				latitude: 4.933329999999998,
				longitude: -52.33334000000001
			},
			{
				name: 'Cayman',
				hasChildren: false,
				fullName: 'America/Cayman',
				countryCode: 'KY',
				latitude: 19.299999999999997,
				longitude: -81.38334
			},
			{
				name: 'Chicago',
				hasChildren: false,
				fullName: 'America/Chicago',
				countryCode: 'US',
				latitude: 41.849999999999994,
				longitude: -87.65,
				comments: 'Central (most areas)'
			},
			{
				name: 'Chihuahua',
				hasChildren: false,
				fullName: 'America/Chihuahua',
				countryCode: 'MX',
				latitude: 28.63333,
				longitude: -106.08334,
				comments: 'Mountain Time - Chihuahua (most areas)'
			},
			{
				name: 'Costa_Rica',
				displayName: 'Costa Rica',
				hasChildren: false,
				fullName: 'America/Costa_Rica',
				countryCode: 'CR',
				latitude: 9.933329999999998,
				longitude: -84.08334
			},
			{
				name: 'Creston',
				hasChildren: false,
				fullName: 'America/Creston',
				countryCode: 'CA',
				latitude: 49.099999999999994,
				longitude: -116.51667,
				comments: 'MST - BC (Creston)'
			},
			{
				name: 'Cuiaba',
				hasChildren: false,
				fullName: 'America/Cuiaba',
				countryCode: 'BR',
				latitude: -15.583340000000007,
				longitude: -56.08334000000001,
				comments: 'Mato Grosso'
			},
			{
				name: 'Curacao',
				hasChildren: false,
				fullName: 'America/Curacao',
				countryCode: 'CW',
				latitude: 12.183329999999998,
				longitude: -69
			},
			{
				name: 'Danmarkshavn',
				hasChildren: false,
				fullName: 'America/Danmarkshavn',
				countryCode: 'GL',
				latitude: 76.76666,
				longitude: -18.66667000000001,
				comments: 'National Park (east coast)'
			},
			{
				name: 'Dawson',
				hasChildren: false,
				fullName: 'America/Dawson',
				countryCode: 'CA',
				latitude: 64.06666000000001,
				longitude: -139.41667,
				comments: 'MST - Yukon (west)'
			},
			{
				name: 'Dawson_Creek',
				displayName: 'Dawson Creek',
				hasChildren: false,
				fullName: 'America/Dawson_Creek',
				countryCode: 'CA',
				latitude: 59.76666,
				longitude: -120.23334,
				comments: 'MST - BC (Dawson Cr, Ft St John)'
			},
			{
				name: 'Denver',
				hasChildren: false,
				fullName: 'America/Denver',
				countryCode: 'US',
				latitude: 39.73916,
				longitude: -104.98417,
				comments: 'Mountain (most areas)'
			},
			{
				name: 'Detroit',
				hasChildren: false,
				fullName: 'America/Detroit',
				countryCode: 'US',
				latitude: 42.331379999999996,
				longitude: -83.04584,
				comments: 'Eastern - MI (most areas)'
			},
			{
				name: 'Dominica',
				hasChildren: false,
				fullName: 'America/Dominica',
				countryCode: 'DM',
				latitude: 15.299999999999997,
				longitude: -61.400000000000006
			},
			{
				name: 'Edmonton',
				hasChildren: false,
				fullName: 'America/Edmonton',
				countryCode: 'CA',
				latitude: 53.55000000000001,
				longitude: -113.46667,
				comments: 'Mountain - AB; BC (E); SK (W)'
			},
			{
				name: 'Eirunepe',
				hasChildren: false,
				fullName: 'America/Eirunepe',
				countryCode: 'BR',
				latitude: -6.666669999999996,
				longitude: -69.86667,
				comments: 'Amazonas (west)'
			},
			{
				name: 'El_Salvador',
				displayName: 'El Salvador',
				hasChildren: false,
				fullName: 'America/El_Salvador',
				countryCode: 'SV',
				latitude: 13.700000000000003,
				longitude: -89.2
			},
			{
				name: 'Fort_Nelson',
				displayName: 'Fort Nelson',
				hasChildren: false,
				fullName: 'America/Fort_Nelson',
				countryCode: 'CA',
				latitude: 58.80000000000001,
				longitude: -122.7,
				comments: 'MST - BC (Ft Nelson)'
			},
			{
				name: 'Fortaleza',
				hasChildren: false,
				fullName: 'America/Fortaleza',
				countryCode: 'BR',
				latitude: -3.7166699999999935,
				longitude: -38.5,
				comments: 'Brazil (northeast: MA, PI, CE, RN, PB)'
			},
			{
				name: 'Glace_Bay',
				displayName: 'Glace Bay',
				hasChildren: false,
				fullName: 'America/Glace_Bay',
				countryCode: 'CA',
				latitude: 46.199990000000014,
				longitude: -59.95,
				comments: 'Atlantic - NS (Cape Breton)'
			},
			{
				name: 'Goose_Bay',
				displayName: 'Goose Bay',
				hasChildren: false,
				fullName: 'America/Goose_Bay',
				countryCode: 'CA',
				latitude: 53.33332999999999,
				longitude: -60.416669999999996,
				comments: 'Atlantic - Labrador (most areas)'
			},
			{
				name: 'Grand_Turk',
				displayName: 'Grand Turk',
				hasChildren: false,
				fullName: 'America/Grand_Turk',
				countryCode: 'TC',
				latitude: 21.466660000000005,
				longitude: -71.13334
			},
			{
				name: 'Grenada',
				hasChildren: false,
				fullName: 'America/Grenada',
				countryCode: 'GD',
				latitude: 12.049999999999997,
				longitude: -61.75
			},
			{
				name: 'Guadeloupe',
				hasChildren: false,
				fullName: 'America/Guadeloupe',
				countryCode: 'GP',
				latitude: 16.233329999999995,
				longitude: -61.533339999999995
			},
			{
				name: 'Guatemala',
				hasChildren: false,
				fullName: 'America/Guatemala',
				countryCode: 'GT',
				latitude: 14.63333,
				longitude: -90.51667
			},
			{
				name: 'Guayaquil',
				hasChildren: false,
				fullName: 'America/Guayaquil',
				countryCode: 'EC',
				latitude: -2.1666699999999963,
				longitude: -79.83334,
				comments: 'Ecuador (mainland)'
			},
			{
				name: 'Guyana',
				hasChildren: false,
				fullName: 'America/Guyana',
				countryCode: 'GY',
				latitude: 6.799999999999997,
				longitude: -58.166669999999996
			},
			{
				name: 'Halifax',
				hasChildren: false,
				fullName: 'America/Halifax',
				countryCode: 'CA',
				latitude: 44.650000000000006,
				longitude: -63.599999999999994,
				comments: 'Atlantic - NS (most areas); PE'
			},
			{
				name: 'Havana',
				hasChildren: false,
				fullName: 'America/Havana',
				countryCode: 'CU',
				latitude: 23.13333,
				longitude: -82.36667
			},
			{
				name: 'Hermosillo',
				hasChildren: false,
				fullName: 'America/Hermosillo',
				countryCode: 'MX',
				latitude: 29.06666,
				longitude: -110.96667,
				comments: 'Mountain Standard Time - Sonora'
			},
			{
				name: 'Indiana',
				hasChildren: true,
				subZones: [
					{
						name: 'Indianapolis',
						hasChildren: false,
						fullName: 'America/Indiana/Indianapolis',
						countryCode: 'US',
						latitude: 39.76832999999999,
						longitude: -86.15806,
						comments: 'Eastern - IN (most areas)'
					},
					{
						name: 'Knox',
						hasChildren: false,
						fullName: 'America/Indiana/Knox',
						countryCode: 'US',
						latitude: 41.295829999999995,
						longitude: -86.625,
						comments: 'Central - IN (Starke)'
					},
					{
						name: 'Marengo',
						hasChildren: false,
						fullName: 'America/Indiana/Marengo',
						countryCode: 'US',
						latitude: 38.375550000000004,
						longitude: -86.34473,
						comments: 'Eastern - IN (Crawford)'
					},
					{
						name: 'Petersburg',
						hasChildren: false,
						fullName: 'America/Indiana/Petersburg',
						countryCode: 'US',
						latitude: 38.49194,
						longitude: -87.27862,
						comments: 'Eastern - IN (Pike)'
					},
					{
						name: 'Tell_City',
						displayName: 'Tell City',
						hasChildren: false,
						fullName: 'America/Indiana/Tell_City',
						countryCode: 'US',
						latitude: 37.953050000000005,
						longitude: -86.76139,
						comments: 'Central - IN (Perry)'
					},
					{
						name: 'Vevay',
						hasChildren: false,
						fullName: 'America/Indiana/Vevay',
						countryCode: 'US',
						latitude: 38.74777,
						longitude: -85.06723,
						comments: 'Eastern - IN (Switzerland)'
					},
					{
						name: 'Vincennes',
						hasChildren: false,
						fullName: 'America/Indiana/Vincennes',
						countryCode: 'US',
						latitude: 38.677220000000005,
						longitude: -87.52862,
						comments: 'Eastern - IN (Da, Du, K, Mn)'
					},
					{
						name: 'Winamac',
						hasChildren: false,
						fullName: 'America/Indiana/Winamac',
						countryCode: 'US',
						latitude: 41.051379999999995,
						longitude: -86.60306,
						comments: 'Eastern - IN (Pulaski)'
					}
				]
			},
			{
				name: 'Inuvik',
				hasChildren: false,
				fullName: 'America/Inuvik',
				countryCode: 'CA',
				latitude: 68.34971999999999,
				longitude: -133.71667,
				comments: 'Mountain - NT (west)'
			},
			{
				name: 'Iqaluit',
				hasChildren: false,
				fullName: 'America/Iqaluit',
				countryCode: 'CA',
				latitude: 63.733329999999995,
				longitude: -68.46667,
				comments: 'Eastern - NU (most east areas)'
			},
			{
				name: 'Jamaica',
				hasChildren: false,
				fullName: 'America/Jamaica',
				countryCode: 'JM',
				latitude: 17.968050000000005,
				longitude: -76.79334
			},
			{
				name: 'Juneau',
				hasChildren: false,
				fullName: 'America/Juneau',
				countryCode: 'US',
				latitude: 58.30194,
				longitude: -134.41973000000002,
				comments: 'Alaska - Juneau area'
			},
			{
				name: 'Kentucky',
				hasChildren: true,
				subZones: [
					{
						name: 'Louisville',
						hasChildren: false,
						fullName: 'America/Kentucky/Louisville',
						countryCode: 'US',
						latitude: 38.25416000000001,
						longitude: -85.75945,
						comments: 'Eastern - KY (Louisville area)'
					},
					{
						name: 'Monticello',
						hasChildren: false,
						fullName: 'America/Kentucky/Monticello',
						countryCode: 'US',
						latitude: 36.829719999999995,
						longitude: -84.84917,
						comments: 'Eastern - KY (Wayne)'
					}
				]
			},
			{
				name: 'Kralendijk',
				hasChildren: false,
				fullName: 'America/Kralendijk',
				countryCode: 'BQ',
				latitude: 12.15083,
				longitude: -68.27667
			},
			{
				name: 'La_Paz',
				displayName: 'La Paz',
				hasChildren: false,
				fullName: 'America/La_Paz',
				countryCode: 'BO',
				latitude: -16.5,
				longitude: -68.15
			},
			{
				name: 'Lima',
				hasChildren: false,
				fullName: 'America/Lima',
				countryCode: 'PE',
				latitude: -12.049999999999997,
				longitude: -77.05
			},
			{
				name: 'Los_Angeles',
				displayName: 'Los Angeles',
				hasChildren: false,
				fullName: 'America/Los_Angeles',
				countryCode: 'US',
				latitude: 34.052220000000005,
				longitude: -118.24278000000001,
				comments: 'Pacific'
			},
			{
				name: 'Lower_Princes',
				displayName: 'Lower Princes',
				hasChildren: false,
				fullName: 'America/Lower_Princes',
				countryCode: 'SX',
				latitude: 18.051379999999995,
				longitude: -63.04723
			},
			{
				name: 'Maceio',
				hasChildren: false,
				fullName: 'America/Maceio',
				countryCode: 'BR',
				latitude: -9.666669999999996,
				longitude: -35.71666999999999,
				comments: 'Alagoas, Sergipe'
			},
			{
				name: 'Managua',
				hasChildren: false,
				fullName: 'America/Managua',
				countryCode: 'NI',
				latitude: 12.150000000000006,
				longitude: -86.28334
			},
			{
				name: 'Manaus',
				hasChildren: false,
				fullName: 'America/Manaus',
				countryCode: 'BR',
				latitude: -3.133340000000004,
				longitude: -60.016670000000005,
				comments: 'Amazonas (east)'
			},
			{
				name: 'Marigot',
				hasChildren: false,
				fullName: 'America/Marigot',
				countryCode: 'MF',
				latitude: 18.06666,
				longitude: -63.08334000000001
			},
			{
				name: 'Martinique',
				hasChildren: false,
				fullName: 'America/Martinique',
				countryCode: 'MQ',
				latitude: 14.599999999999994,
				longitude: -61.08334000000001
			},
			{
				name: 'Matamoros',
				hasChildren: false,
				fullName: 'America/Matamoros',
				countryCode: 'MX',
				latitude: 25.833330000000004,
				longitude: -97.5,
				comments:
					'Central Time US - Coahuila, Nuevo Leon, Tamaulipas (US border)'
			},
			{
				name: 'Mazatlan',
				hasChildren: false,
				fullName: 'America/Mazatlan',
				countryCode: 'MX',
				latitude: 23.216660000000005,
				longitude: -106.41667,
				comments:
					'Mountain Time - Baja California Sur, Nayarit, Sinaloa'
			},
			{
				name: 'Menominee',
				hasChildren: false,
				fullName: 'America/Menominee',
				countryCode: 'US',
				latitude: 45.10776999999999,
				longitude: -87.61417,
				comments: 'Central - MI (Wisconsin border)'
			},
			{
				name: 'Merida',
				hasChildren: false,
				fullName: 'America/Merida',
				countryCode: 'MX',
				latitude: 20.966660000000005,
				longitude: -89.61667,
				comments: 'Central Time - Campeche, Yucatan'
			},
			{
				name: 'Metlakatla',
				hasChildren: false,
				fullName: 'America/Metlakatla',
				countryCode: 'US',
				latitude: 55.12693999999999,
				longitude: -131.57639,
				comments: 'Alaska - Annette Island'
			},
			{
				name: 'Mexico_City',
				displayName: 'Mexico City',
				hasChildren: false,
				fullName: 'America/Mexico_City',
				countryCode: 'MX',
				latitude: 19.400000000000006,
				longitude: -99.15001,
				comments: 'Central Time'
			},
			{
				name: 'Miquelon',
				hasChildren: false,
				fullName: 'America/Miquelon',
				countryCode: 'PM',
				latitude: 47.05000000000001,
				longitude: -56.33334000000001
			},
			{
				name: 'Moncton',
				hasChildren: false,
				fullName: 'America/Moncton',
				countryCode: 'CA',
				latitude: 46.099999999999994,
				longitude: -64.78334,
				comments: 'Atlantic - New Brunswick'
			},
			{
				name: 'Monterrey',
				hasChildren: false,
				fullName: 'America/Monterrey',
				countryCode: 'MX',
				latitude: 25.666659999999993,
				longitude: -100.31667,
				comments:
					'Central Time - Durango; Coahuila, Nuevo Leon, Tamaulipas (most areas)'
			},
			{
				name: 'Montevideo',
				hasChildren: false,
				fullName: 'America/Montevideo',
				countryCode: 'UY',
				latitude: -34.90917,
				longitude: -56.212500000000006
			},
			{
				name: 'Montserrat',
				hasChildren: false,
				fullName: 'America/Montserrat',
				countryCode: 'MS',
				latitude: 16.716660000000005,
				longitude: -62.21666999999999
			},
			{
				name: 'Nassau',
				hasChildren: false,
				fullName: 'America/Nassau',
				countryCode: 'BS',
				latitude: 25.083330000000004,
				longitude: -77.35
			},
			{
				name: 'New_York',
				displayName: 'New York',
				hasChildren: false,
				fullName: 'America/New_York',
				countryCode: 'US',
				latitude: 40.71415999999999,
				longitude: -74.00639,
				comments: 'Eastern (most areas)'
			},
			{
				name: 'Nipigon',
				hasChildren: false,
				fullName: 'America/Nipigon',
				countryCode: 'CA',
				latitude: 49.01666,
				longitude: -88.26667,
				comments: 'Eastern - ON, QC (no DST 1967-73)'
			},
			{
				name: 'Nome',
				hasChildren: false,
				fullName: 'America/Nome',
				countryCode: 'US',
				latitude: 64.50111000000001,
				longitude: -165.40639,
				comments: 'Alaska (west)'
			},
			{
				name: 'Noronha',
				hasChildren: false,
				fullName: 'America/Noronha',
				countryCode: 'BR',
				latitude: -3.8499999999999943,
				longitude: -32.41667000000001,
				comments: 'Atlantic islands'
			},
			{
				name: 'North_Dakota',
				displayName: 'North Dakota',
				hasChildren: true,
				subZones: [
					{
						name: 'Beulah',
						hasChildren: false,
						fullName: 'America/North_Dakota/Beulah',
						countryCode: 'US',
						latitude: 47.264160000000004,
						longitude: -101.77778,
						comments: 'Central - ND (Mercer)'
					},
					{
						name: 'Center',
						hasChildren: false,
						fullName: 'America/North_Dakota/Center',
						countryCode: 'US',
						latitude: 47.11637999999999,
						longitude: -101.29917,
						comments: 'Central - ND (Oliver)'
					},
					{
						name: 'New_Salem',
						displayName: 'New Salem',
						hasChildren: false,
						fullName: 'America/North_Dakota/New_Salem',
						countryCode: 'US',
						latitude: 46.845,
						longitude: -101.41084,
						comments: 'Central - ND (Morton rural)'
					}
				]
			},
			{
				name: 'Nuuk',
				hasChildren: false,
				fullName: 'America/Nuuk',
				countryCode: 'GL',
				latitude: 64.18333000000001,
				longitude: -51.73334,
				comments: 'Greenland (most areas)'
			},
			{
				name: 'Ojinaga',
				hasChildren: false,
				fullName: 'America/Ojinaga',
				countryCode: 'MX',
				latitude: 29.56666,
				longitude: -104.41667,
				comments: 'Mountain Time US - Chihuahua (US border)'
			},
			{
				name: 'Panama',
				hasChildren: false,
				fullName: 'America/Panama',
				countryCode: 'PA',
				latitude: 8.966660000000005,
				longitude: -79.53334
			},
			{
				name: 'Pangnirtung',
				hasChildren: false,
				fullName: 'America/Pangnirtung',
				countryCode: 'CA',
				latitude: 66.13333,
				longitude: -65.73334,
				comments: 'Eastern - NU (Pangnirtung)'
			},
			{
				name: 'Paramaribo',
				hasChildren: false,
				fullName: 'America/Paramaribo',
				countryCode: 'SR',
				latitude: 5.833330000000004,
				longitude: -55.166669999999996
			},
			{
				name: 'Phoenix',
				hasChildren: false,
				fullName: 'America/Phoenix',
				countryCode: 'US',
				latitude: 33.44833,
				longitude: -112.07334,
				comments: 'MST - Arizona (except Navajo)'
			},
			{
				name: 'Port-au-Prince',
				hasChildren: false,
				fullName: 'America/Port-au-Prince',
				countryCode: 'HT',
				latitude: 18.533330000000007,
				longitude: -72.33334
			},
			{
				name: 'Port_of_Spain',
				displayName: 'Port of Spain',
				hasChildren: false,
				fullName: 'America/Port_of_Spain',
				countryCode: 'TT',
				latitude: 10.650000000000006,
				longitude: -61.516670000000005
			},
			{
				name: 'Porto_Velho',
				displayName: 'Porto Velho',
				hasChildren: false,
				fullName: 'America/Porto_Velho',
				countryCode: 'BR',
				latitude: -8.766670000000005,
				longitude: -63.900000000000006,
				comments: 'Rondonia'
			},
			{
				name: 'Puerto_Rico',
				displayName: 'Puerto Rico',
				hasChildren: false,
				fullName: 'America/Puerto_Rico',
				countryCode: 'PR',
				latitude: 18.468329999999995,
				longitude: -66.10612
			},
			{
				name: 'Punta_Arenas',
				displayName: 'Punta Arenas',
				hasChildren: false,
				fullName: 'America/Punta_Arenas',
				countryCode: 'CL',
				latitude: -53.15,
				longitude: -70.91667,
				comments: 'Region of Magallanes'
			},
			{
				name: 'Rainy_River',
				displayName: 'Rainy River',
				hasChildren: false,
				fullName: 'America/Rainy_River',
				countryCode: 'CA',
				latitude: 48.71665999999999,
				longitude: -94.56667,
				comments: 'Central - ON (Rainy R, Ft Frances)'
			},
			{
				name: 'Rankin_Inlet',
				displayName: 'Rankin Inlet',
				hasChildren: false,
				fullName: 'America/Rankin_Inlet',
				countryCode: 'CA',
				latitude: 62.81666000000001,
				longitude: -92.08306,
				comments: 'Central - NU (central)'
			},
			{
				name: 'Recife',
				hasChildren: false,
				fullName: 'America/Recife',
				countryCode: 'BR',
				latitude: -8.049999999999997,
				longitude: -34.900000000000006,
				comments: 'Pernambuco'
			},
			{
				name: 'Regina',
				hasChildren: false,
				fullName: 'America/Regina',
				countryCode: 'CA',
				latitude: 50.400000000000006,
				longitude: -104.65001,
				comments: 'CST - SK (most areas)'
			},
			{
				name: 'Resolute',
				hasChildren: false,
				fullName: 'America/Resolute',
				countryCode: 'CA',
				latitude: 74.69555,
				longitude: -94.82917,
				comments: 'Central - NU (Resolute)'
			},
			{
				name: 'Rio_Branco',
				displayName: 'Rio Branco',
				hasChildren: false,
				fullName: 'America/Rio_Branco',
				countryCode: 'BR',
				latitude: -9.966669999999993,
				longitude: -67.8,
				comments: 'Acre'
			},
			{
				name: 'Santarem',
				hasChildren: false,
				fullName: 'America/Santarem',
				countryCode: 'BR',
				latitude: -2.433340000000001,
				longitude: -54.86667,
				comments: 'Para (west)'
			},
			{
				name: 'Santiago',
				hasChildren: false,
				fullName: 'America/Santiago',
				countryCode: 'CL',
				latitude: -33.45,
				longitude: -70.66667,
				comments: 'Chile (most areas)'
			},
			{
				name: 'Santo_Domingo',
				displayName: 'Santo Domingo',
				hasChildren: false,
				fullName: 'America/Santo_Domingo',
				countryCode: 'DO',
				latitude: 18.466660000000005,
				longitude: -69.9
			},
			{
				name: 'Sao_Paulo',
				displayName: 'Sao Paulo',
				hasChildren: false,
				fullName: 'America/Sao_Paulo',
				countryCode: 'BR',
				latitude: -23.533339999999995,
				longitude: -46.61667,
				comments:
					'Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)'
			},
			{
				name: 'Scoresbysund',
				hasChildren: false,
				fullName: 'America/Scoresbysund',
				countryCode: 'GL',
				latitude: 70.48333,
				longitude: -21.966669999999993,
				comments: 'Scoresbysund/Ittoqqortoormiit'
			},
			{
				name: 'Sitka',
				hasChildren: false,
				fullName: 'America/Sitka',
				countryCode: 'US',
				latitude: 57.176379999999995,
				longitude: -135.30195,
				comments: 'Alaska - Sitka area'
			},
			{
				name: 'St_Barthelemy',
				displayName: 'St Barthelemy',
				hasChildren: false,
				fullName: 'America/St_Barthelemy',
				countryCode: 'BL',
				latitude: 17.88333,
				longitude: -62.849999999999994
			},
			{
				name: 'St_Johns',
				displayName: 'St Johns',
				hasChildren: false,
				fullName: 'America/St_Johns',
				countryCode: 'CA',
				latitude: 47.56666000000001,
				longitude: -52.71666999999999,
				comments: 'Newfoundland; Labrador (southeast)'
			},
			{
				name: 'St_Kitts',
				displayName: 'St Kitts',
				hasChildren: false,
				fullName: 'America/St_Kitts',
				countryCode: 'KN',
				latitude: 17.299999999999997,
				longitude: -62.71666999999999
			},
			{
				name: 'St_Lucia',
				displayName: 'St Lucia',
				hasChildren: false,
				fullName: 'America/St_Lucia',
				countryCode: 'LC',
				latitude: 14.016660000000002,
				longitude: -61
			},
			{
				name: 'St_Thomas',
				displayName: 'St Thomas',
				hasChildren: false,
				fullName: 'America/St_Thomas',
				countryCode: 'VI',
				latitude: 18.349999999999994,
				longitude: -64.93334
			},
			{
				name: 'St_Vincent',
				displayName: 'St Vincent',
				hasChildren: false,
				fullName: 'America/St_Vincent',
				countryCode: 'VC',
				latitude: 13.150000000000006,
				longitude: -61.23334
			},
			{
				name: 'Swift_Current',
				displayName: 'Swift Current',
				hasChildren: false,
				fullName: 'America/Swift_Current',
				countryCode: 'CA',
				latitude: 50.28333000000001,
				longitude: -107.83334,
				comments: 'CST - SK (midwest)'
			},
			{
				name: 'Tegucigalpa',
				hasChildren: false,
				fullName: 'America/Tegucigalpa',
				countryCode: 'HN',
				latitude: 14.099999999999994,
				longitude: -87.21667
			},
			{
				name: 'Thule',
				hasChildren: false,
				fullName: 'America/Thule',
				countryCode: 'GL',
				latitude: 76.56666000000001,
				longitude: -68.78334,
				comments: 'Thule/Pituffik'
			},
			{
				name: 'Thunder_Bay',
				displayName: 'Thunder Bay',
				hasChildren: false,
				fullName: 'America/Thunder_Bay',
				countryCode: 'CA',
				latitude: 48.38333,
				longitude: -89.25,
				comments: 'Eastern - ON (Thunder Bay)'
			},
			{
				name: 'Tijuana',
				hasChildren: false,
				fullName: 'America/Tijuana',
				countryCode: 'MX',
				latitude: 32.53333000000001,
				longitude: -117.01667,
				comments: 'Pacific Time US - Baja California'
			},
			{
				name: 'Toronto',
				hasChildren: false,
				fullName: 'America/Toronto',
				countryCode: 'CA',
				latitude: 43.650000000000006,
				longitude: -79.38334,
				comments: 'Eastern - ON, QC (most areas)'
			},
			{
				name: 'Tortola',
				hasChildren: false,
				fullName: 'America/Tortola',
				countryCode: 'VG',
				latitude: 18.450000000000003,
				longitude: -64.61667
			},
			{
				name: 'Vancouver',
				hasChildren: false,
				fullName: 'America/Vancouver',
				countryCode: 'CA',
				latitude: 49.26666,
				longitude: -123.11667,
				comments: 'Pacific - BC (most areas)'
			},
			{
				name: 'Whitehorse',
				hasChildren: false,
				fullName: 'America/Whitehorse',
				countryCode: 'CA',
				latitude: 60.71665999999999,
				longitude: -135.05001,
				comments: 'MST - Yukon (east)'
			},
			{
				name: 'Winnipeg',
				hasChildren: false,
				fullName: 'America/Winnipeg',
				countryCode: 'CA',
				latitude: 49.88333,
				longitude: -97.15001,
				comments: 'Central - ON (west); Manitoba'
			},
			{
				name: 'Yakutat',
				hasChildren: false,
				fullName: 'America/Yakutat',
				countryCode: 'US',
				latitude: 59.546940000000006,
				longitude: -139.72723,
				comments: 'Alaska - Yakutat'
			},
			{
				name: 'Yellowknife',
				hasChildren: false,
				fullName: 'America/Yellowknife',
				countryCode: 'CA',
				latitude: 62.449990000000014,
				longitude: -114.35,
				comments: 'Mountain - NT (central)'
			}
		]
	},
	{
		name: 'Antarctica',
		hasChildren: true,
		subZones: [
			{
				name: 'Casey',
				hasChildren: false,
				fullName: 'Antarctica/Casey',
				countryCode: 'AQ',
				latitude: -66.28334,
				longitude: 110.51666,
				comments: 'Casey'
			},
			{
				name: 'Davis',
				hasChildren: false,
				fullName: 'Antarctica/Davis',
				countryCode: 'AQ',
				latitude: -68.58333999999999,
				longitude: 77.96665999999999,
				comments: 'Davis'
			},
			{
				name: 'DumontDUrville',
				hasChildren: false,
				fullName: 'Antarctica/DumontDUrville',
				countryCode: 'AQ',
				latitude: -66.66667,
				longitude: 140.01666,
				comments: "Dumont-d'Urville"
			},
			{
				name: 'Macquarie',
				hasChildren: false,
				fullName: 'Antarctica/Macquarie',
				countryCode: 'AU',
				latitude: -54.5,
				longitude: 158.95,
				comments: 'Macquarie Island'
			},
			{
				name: 'Mawson',
				hasChildren: false,
				fullName: 'Antarctica/Mawson',
				countryCode: 'AQ',
				latitude: -67.6,
				longitude: 62.88333,
				comments: 'Mawson'
			},
			{
				name: 'McMurdo',
				hasChildren: false,
				fullName: 'Antarctica/McMurdo',
				countryCode: 'AQ',
				latitude: -77.83333999999999,
				longitude: 166.60000000000002,
				comments: 'New Zealand time - McMurdo, South Pole'
			},
			{
				name: 'Palmer',
				hasChildren: false,
				fullName: 'Antarctica/Palmer',
				countryCode: 'AQ',
				latitude: -64.8,
				longitude: -64.1,
				comments: 'Palmer'
			},
			{
				name: 'Rothera',
				hasChildren: false,
				fullName: 'Antarctica/Rothera',
				countryCode: 'AQ',
				latitude: -67.56667,
				longitude: -68.13334,
				comments: 'Rothera'
			},
			{
				name: 'Syowa',
				hasChildren: false,
				fullName: 'Antarctica/Syowa',
				countryCode: 'AQ',
				latitude: -69.00612,
				longitude: 39.59,
				comments: 'Syowa'
			},
			{
				name: 'Troll',
				hasChildren: false,
				fullName: 'Antarctica/Troll',
				countryCode: 'AQ',
				latitude: -72.01139,
				longitude: 2.5349999999999966,
				comments: 'Troll'
			},
			{
				name: 'Vostok',
				hasChildren: false,
				fullName: 'Antarctica/Vostok',
				countryCode: 'AQ',
				latitude: -78.40001,
				longitude: 106.89999,
				comments: 'Vostok'
			}
		]
	},
	{
		name: 'Arctic',
		hasChildren: true,
		subZones: [
			{
				name: 'Longyearbyen',
				hasChildren: false,
				fullName: 'Arctic/Longyearbyen',
				countryCode: 'SJ',
				latitude: 78,
				longitude: 16
			}
		]
	},
	{
		name: 'Asia',
		hasChildren: true,
		subZones: [
			{
				name: 'Aden',
				hasChildren: false,
				fullName: 'Asia/Aden',
				countryCode: 'YE',
				latitude: 12.75,
				longitude: 45.19999999999999
			},
			{
				name: 'Almaty',
				hasChildren: false,
				fullName: 'Asia/Almaty',
				countryCode: 'KZ',
				latitude: 43.25,
				longitude: 76.94999999999999,
				comments: 'Kazakhstan (most areas)'
			},
			{
				name: 'Amman',
				hasChildren: false,
				fullName: 'Asia/Amman',
				countryCode: 'JO',
				latitude: 31.950000000000003,
				longitude: 35.93333000000001
			},
			{
				name: 'Anadyr',
				hasChildren: false,
				fullName: 'Asia/Anadyr',
				countryCode: 'RU',
				latitude: 64.75,
				longitude: 177.48333000000002,
				comments: 'MSK+09 - Bering Sea'
			},
			{
				name: 'Aqtau',
				hasChildren: false,
				fullName: 'Asia/Aqtau',
				countryCode: 'KZ',
				latitude: 44.51666,
				longitude: 50.26666,
				comments: 'Mangghystau/Mankistau'
			},
			{
				name: 'Aqtobe',
				hasChildren: false,
				fullName: 'Asia/Aqtobe',
				countryCode: 'KZ',
				latitude: 50.28333000000001,
				longitude: 57.16666000000001,
				comments: 'Aqtobe/Aktobe'
			},
			{
				name: 'Ashgabat',
				hasChildren: false,
				fullName: 'Asia/Ashgabat',
				countryCode: 'TM',
				latitude: 37.95,
				longitude: 58.38333
			},
			{
				name: 'Atyrau',
				hasChildren: false,
				fullName: 'Asia/Atyrau',
				countryCode: 'KZ',
				latitude: 47.116659999999996,
				longitude: 51.93333000000001,
				comments: "Atyrau/Atirau/Gur'yev"
			},
			{
				name: 'Baghdad',
				hasChildren: false,
				fullName: 'Asia/Baghdad',
				countryCode: 'IQ',
				latitude: 33.349999999999994,
				longitude: 44.41666000000001
			},
			{
				name: 'Bahrain',
				hasChildren: false,
				fullName: 'Asia/Bahrain',
				countryCode: 'BH',
				latitude: 26.38333,
				longitude: 50.58332999999999
			},
			{
				name: 'Baku',
				hasChildren: false,
				fullName: 'Asia/Baku',
				countryCode: 'AZ',
				latitude: 40.38333,
				longitude: 49.849999999999994
			},
			{
				name: 'Bangkok',
				hasChildren: false,
				fullName: 'Asia/Bangkok',
				countryCode: 'TH',
				latitude: 13.75,
				longitude: 100.51666
			},
			{
				name: 'Barnaul',
				hasChildren: false,
				fullName: 'Asia/Barnaul',
				countryCode: 'RU',
				latitude: 53.366659999999996,
				longitude: 83.75,
				comments: 'MSK+04 - Altai'
			},
			{
				name: 'Beirut',
				hasChildren: false,
				fullName: 'Asia/Beirut',
				countryCode: 'LB',
				latitude: 33.88333,
				longitude: 35.5
			},
			{
				name: 'Bishkek',
				hasChildren: false,
				fullName: 'Asia/Bishkek',
				countryCode: 'KG',
				latitude: 42.900000000000006,
				longitude: 74.6
			},
			{
				name: 'Brunei',
				hasChildren: false,
				fullName: 'Asia/Brunei',
				countryCode: 'BN',
				latitude: 4.933329999999998,
				longitude: 114.91665999999998
			},
			{
				name: 'Chita',
				hasChildren: false,
				fullName: 'Asia/Chita',
				countryCode: 'RU',
				latitude: 52.05000000000001,
				longitude: 113.46665999999999,
				comments: 'MSK+06 - Zabaykalsky'
			},
			{
				name: 'Choibalsan',
				hasChildren: false,
				fullName: 'Asia/Choibalsan',
				countryCode: 'MN',
				latitude: 48.06666000000001,
				longitude: 114.5,
				comments: 'Dornod, Sukhbaatar'
			},
			{
				name: 'Colombo',
				hasChildren: false,
				fullName: 'Asia/Colombo',
				countryCode: 'LK',
				latitude: 6.933329999999998,
				longitude: 79.85000000000002
			},
			{
				name: 'Damascus',
				hasChildren: false,
				fullName: 'Asia/Damascus',
				countryCode: 'SY',
				latitude: 33.5,
				longitude: 36.30000000000001
			},
			{
				name: 'Dhaka',
				hasChildren: false,
				fullName: 'Asia/Dhaka',
				countryCode: 'BD',
				latitude: 23.716660000000005,
				longitude: 90.41665999999998
			},
			{
				name: 'Dili',
				hasChildren: false,
				fullName: 'Asia/Dili',
				countryCode: 'TL',
				latitude: -8.549999999999997,
				longitude: 125.58332999999999
			},
			{
				name: 'Dubai',
				hasChildren: false,
				fullName: 'Asia/Dubai',
				countryCode: 'AE',
				latitude: 25.299999999999997,
				longitude: 55.30000000000001
			},
			{
				name: 'Dushanbe',
				hasChildren: false,
				fullName: 'Asia/Dushanbe',
				countryCode: 'TJ',
				latitude: 38.58332999999999,
				longitude: 68.80000000000001
			},
			{
				name: 'Famagusta',
				hasChildren: false,
				fullName: 'Asia/Famagusta',
				countryCode: 'CY',
				latitude: 35.116659999999996,
				longitude: 33.94999999999999,
				comments: 'Northern Cyprus'
			},
			{
				name: 'Gaza',
				hasChildren: false,
				fullName: 'Asia/Gaza',
				countryCode: 'PS',
				latitude: 31.5,
				longitude: 34.46665999999999,
				comments: 'Gaza Strip'
			},
			{
				name: 'Hebron',
				hasChildren: false,
				fullName: 'Asia/Hebron',
				countryCode: 'PS',
				latitude: 31.533330000000007,
				longitude: 35.095,
				comments: 'West Bank'
			},
			{
				name: 'Ho_Chi_Minh',
				displayName: 'Ho Chi Minh',
				hasChildren: false,
				fullName: 'Asia/Ho_Chi_Minh',
				countryCode: 'VN',
				latitude: 10.75,
				longitude: 106.66665999999998
			},
			{
				name: 'Hong_Kong',
				displayName: 'Hong Kong',
				hasChildren: false,
				fullName: 'Asia/Hong_Kong',
				countryCode: 'HK',
				latitude: 22.283330000000007,
				longitude: 114.14999
			},
			{
				name: 'Hovd',
				hasChildren: false,
				fullName: 'Asia/Hovd',
				countryCode: 'MN',
				latitude: 48.01666,
				longitude: 91.64999,
				comments: 'Bayan-Olgiy, Govi-Altai, Hovd, Uvs, Zavkhan'
			},
			{
				name: 'Irkutsk',
				hasChildren: false,
				fullName: 'Asia/Irkutsk',
				countryCode: 'RU',
				latitude: 52.26666,
				longitude: 104.33332999999999,
				comments: 'MSK+05 - Irkutsk, Buryatia'
			},
			{
				name: 'Jakarta',
				hasChildren: false,
				fullName: 'Asia/Jakarta',
				countryCode: 'ID',
				latitude: -6.166669999999996,
				longitude: 106.80000000000001,
				comments: 'Java, Sumatra'
			},
			{
				name: 'Jayapura',
				hasChildren: false,
				fullName: 'Asia/Jayapura',
				countryCode: 'ID',
				latitude: -2.5333399999999955,
				longitude: 140.7,
				comments:
					'New Guinea (West Papua / Irian Jaya); Malukus/Moluccas'
			},
			{
				name: 'Jerusalem',
				hasChildren: false,
				fullName: 'Asia/Jerusalem',
				countryCode: 'IL',
				latitude: 31.780550000000005,
				longitude: 35.22388000000001
			},
			{
				name: 'Kabul',
				hasChildren: false,
				fullName: 'Asia/Kabul',
				countryCode: 'AF',
				latitude: 34.51666,
				longitude: 69.19999999999999
			},
			{
				name: 'Kamchatka',
				hasChildren: false,
				fullName: 'Asia/Kamchatka',
				countryCode: 'RU',
				latitude: 53.01666,
				longitude: 158.64999999999998,
				comments: 'MSK+09 - Kamchatka'
			},
			{
				name: 'Karachi',
				hasChildren: false,
				fullName: 'Asia/Karachi',
				countryCode: 'PK',
				latitude: 24.866659999999996,
				longitude: 67.05000000000001
			},
			{
				name: 'Kathmandu',
				hasChildren: false,
				fullName: 'Asia/Kathmandu',
				countryCode: 'NP',
				latitude: 27.716660000000005,
				longitude: 85.31666000000001
			},
			{
				name: 'Khandyga',
				hasChildren: false,
				fullName: 'Asia/Khandyga',
				countryCode: 'RU',
				latitude: 62.65638000000001,
				longitude: 135.55388,
				comments: 'MSK+06 - Tomponsky, Ust-Maysky'
			},
			{
				name: 'Kolkata',
				hasChildren: false,
				fullName: 'Asia/Kolkata',
				countryCode: 'IN',
				latitude: 22.533330000000007,
				longitude: 88.36666000000002
			},
			{
				name: 'Krasnoyarsk',
				hasChildren: false,
				fullName: 'Asia/Krasnoyarsk',
				countryCode: 'RU',
				latitude: 56.01666,
				longitude: 92.83332999999999,
				comments: 'MSK+04 - Krasnoyarsk area'
			},
			{
				name: 'Kuala_Lumpur',
				displayName: 'Kuala Lumpur',
				hasChildren: false,
				fullName: 'Asia/Kuala_Lumpur',
				countryCode: 'MY',
				latitude: 3.166659999999993,
				longitude: 101.69999999999999,
				comments: 'Malaysia (peninsula)'
			},
			{
				name: 'Kuching',
				hasChildren: false,
				fullName: 'Asia/Kuching',
				countryCode: 'MY',
				latitude: 1.5499999999999972,
				longitude: 110.33332999999999,
				comments: 'Sabah, Sarawak'
			},
			{
				name: 'Kuwait',
				hasChildren: false,
				fullName: 'Asia/Kuwait',
				countryCode: 'KW',
				latitude: 29.333330000000004,
				longitude: 47.983329999999995
			},
			{
				name: 'Macau',
				hasChildren: false,
				fullName: 'Asia/Macau',
				countryCode: 'MO',
				latitude: 22.19722,
				longitude: 113.54165999999998
			},
			{
				name: 'Magadan',
				hasChildren: false,
				fullName: 'Asia/Magadan',
				countryCode: 'RU',
				latitude: 59.56666000000001,
				longitude: 150.8,
				comments: 'MSK+08 - Magadan'
			},
			{
				name: 'Makassar',
				hasChildren: false,
				fullName: 'Asia/Makassar',
				countryCode: 'ID',
				latitude: -5.116669999999999,
				longitude: 119.39999,
				comments:
					'Borneo (east, south); Sulawesi/Celebes, Bali, Nusa Tengarra; Timor (west)'
			},
			{
				name: 'Manila',
				hasChildren: false,
				fullName: 'Asia/Manila',
				countryCode: 'PH',
				latitude: 14.583330000000004,
				longitude: 121
			},
			{
				name: 'Muscat',
				hasChildren: false,
				fullName: 'Asia/Muscat',
				countryCode: 'OM',
				latitude: 23.599999999999994,
				longitude: 58.58332999999999
			},
			{
				name: 'Nicosia',
				hasChildren: false,
				fullName: 'Asia/Nicosia',
				countryCode: 'CY',
				latitude: 35.16665999999999,
				longitude: 33.366659999999996,
				comments: 'Cyprus (most areas)'
			},
			{
				name: 'Novokuznetsk',
				hasChildren: false,
				fullName: 'Asia/Novokuznetsk',
				countryCode: 'RU',
				latitude: 53.75,
				longitude: 87.11666000000002,
				comments: 'MSK+04 - Kemerovo'
			},
			{
				name: 'Novosibirsk',
				hasChildren: false,
				fullName: 'Asia/Novosibirsk',
				countryCode: 'RU',
				latitude: 55.03333000000001,
				longitude: 82.91665999999998,
				comments: 'MSK+04 - Novosibirsk'
			},
			{
				name: 'Omsk',
				hasChildren: false,
				fullName: 'Asia/Omsk',
				countryCode: 'RU',
				latitude: 55,
				longitude: 73.4,
				comments: 'MSK+03 - Omsk'
			},
			{
				name: 'Oral',
				hasChildren: false,
				fullName: 'Asia/Oral',
				countryCode: 'KZ',
				latitude: 51.21665999999999,
				longitude: 51.349999999999994,
				comments: 'West Kazakhstan'
			},
			{
				name: 'Phnom_Penh',
				displayName: 'Phnom Penh',
				hasChildren: false,
				fullName: 'Asia/Phnom_Penh',
				countryCode: 'KH',
				latitude: 11.549999999999997,
				longitude: 104.91665999999998
			},
			{
				name: 'Pontianak',
				hasChildren: false,
				fullName: 'Asia/Pontianak',
				countryCode: 'ID',
				latitude: -0.033339999999995484,
				longitude: 109.33332999999999,
				comments: 'Borneo (west, central)'
			},
			{
				name: 'Pyongyang',
				hasChildren: false,
				fullName: 'Asia/Pyongyang',
				countryCode: 'KP',
				latitude: 39.01666,
				longitude: 125.75
			},
			{
				name: 'Qatar',
				hasChildren: false,
				fullName: 'Asia/Qatar',
				countryCode: 'QA',
				latitude: 25.283330000000007,
				longitude: 51.53333000000001
			},
			{
				name: 'Qostanay',
				hasChildren: false,
				fullName: 'Asia/Qostanay',
				countryCode: 'KZ',
				latitude: 53.199990000000014,
				longitude: 63.616659999999996,
				comments: 'Qostanay/Kostanay/Kustanay'
			},
			{
				name: 'Qyzylorda',
				hasChildren: false,
				fullName: 'Asia/Qyzylorda',
				countryCode: 'KZ',
				latitude: 44.80000000000001,
				longitude: 65.46665999999999,
				comments: 'Qyzylorda/Kyzylorda/Kzyl-Orda'
			},
			{
				name: 'Riyadh',
				hasChildren: false,
				fullName: 'Asia/Riyadh',
				countryCode: 'SA',
				latitude: 24.63333,
				longitude: 46.71665999999999
			},
			{
				name: 'Sakhalin',
				hasChildren: false,
				fullName: 'Asia/Sakhalin',
				countryCode: 'RU',
				latitude: 46.96665999999999,
				longitude: 142.7,
				comments: 'MSK+08 - Sakhalin Island'
			},
			{
				name: 'Samarkand',
				hasChildren: false,
				fullName: 'Asia/Samarkand',
				countryCode: 'UZ',
				latitude: 39.66666000000001,
				longitude: 66.80000000000001,
				comments: 'Uzbekistan (west)'
			},
			{
				name: 'Seoul',
				hasChildren: false,
				fullName: 'Asia/Seoul',
				countryCode: 'KR',
				latitude: 37.55,
				longitude: 126.96665999999999
			},
			{
				name: 'Shanghai',
				hasChildren: false,
				fullName: 'Asia/Shanghai',
				countryCode: 'CN',
				latitude: 31.233329999999995,
				longitude: 121.46665999999999,
				comments: 'Beijing Time'
			},
			{
				name: 'Singapore',
				hasChildren: false,
				fullName: 'Asia/Singapore',
				countryCode: 'SG',
				latitude: 1.2833300000000065,
				longitude: 103.85000000000002
			},
			{
				name: 'Srednekolymsk',
				hasChildren: false,
				fullName: 'Asia/Srednekolymsk',
				countryCode: 'RU',
				latitude: 67.46665999999999,
				longitude: 153.71666,
				comments: 'MSK+08 - Sakha (E); North Kuril Is'
			},
			{
				name: 'Taipei',
				hasChildren: false,
				fullName: 'Asia/Taipei',
				countryCode: 'TW',
				latitude: 25.049999999999997,
				longitude: 121.5
			},
			{
				name: 'Tashkent',
				hasChildren: false,
				fullName: 'Asia/Tashkent',
				countryCode: 'UZ',
				latitude: 41.33332999999999,
				longitude: 69.30000000000001,
				comments: 'Uzbekistan (east)'
			},
			{
				name: 'Tbilisi',
				hasChildren: false,
				fullName: 'Asia/Tbilisi',
				countryCode: 'GE',
				latitude: 41.71665999999999,
				longitude: 44.81666000000001
			},
			{
				name: 'Tehran',
				hasChildren: false,
				fullName: 'Asia/Tehran',
				countryCode: 'IR',
				latitude: 35.66665999999999,
				longitude: 51.43333000000001
			},
			{
				name: 'Thimphu',
				hasChildren: false,
				fullName: 'Asia/Thimphu',
				countryCode: 'BT',
				latitude: 27.466660000000005,
				longitude: 89.64999
			},
			{
				name: 'Tokyo',
				hasChildren: false,
				fullName: 'Asia/Tokyo',
				countryCode: 'JP',
				latitude: 35.654439999999994,
				longitude: 139.74471999999997
			},
			{
				name: 'Tomsk',
				hasChildren: false,
				fullName: 'Asia/Tomsk',
				countryCode: 'RU',
				latitude: 56.5,
				longitude: 84.96665999999999,
				comments: 'MSK+04 - Tomsk'
			},
			{
				name: 'Ulaanbaatar',
				hasChildren: false,
				fullName: 'Asia/Ulaanbaatar',
				countryCode: 'MN',
				latitude: 47.91666000000001,
				longitude: 106.88333,
				comments: 'Mongolia (most areas)'
			},
			{
				name: 'Urumqi',
				hasChildren: false,
				fullName: 'Asia/Urumqi',
				countryCode: 'CN',
				latitude: 43.80000000000001,
				longitude: 87.58332999999999,
				comments: 'Xinjiang Time'
			},
			{
				name: 'Ust-Nera',
				hasChildren: false,
				fullName: 'Asia/Ust-Nera',
				countryCode: 'RU',
				latitude: 64.56027,
				longitude: 143.22665999999998,
				comments: 'MSK+07 - Oymyakonsky'
			},
			{
				name: 'Vientiane',
				hasChildren: false,
				fullName: 'Asia/Vientiane',
				countryCode: 'LA',
				latitude: 17.966660000000005,
				longitude: 102.60000000000002
			},
			{
				name: 'Vladivostok',
				hasChildren: false,
				fullName: 'Asia/Vladivostok',
				countryCode: 'RU',
				latitude: 43.16666000000001,
				longitude: 131.93333,
				comments: 'MSK+07 - Amur River'
			},
			{
				name: 'Yakutsk',
				hasChildren: false,
				fullName: 'Asia/Yakutsk',
				countryCode: 'RU',
				latitude: 62,
				longitude: 129.66665999999998,
				comments: 'MSK+06 - Lena River'
			},
			{
				name: 'Yangon',
				hasChildren: false,
				fullName: 'Asia/Yangon',
				countryCode: 'MM',
				latitude: 16.783330000000007,
				longitude: 96.16665999999998
			},
			{
				name: 'Yekaterinburg',
				hasChildren: false,
				fullName: 'Asia/Yekaterinburg',
				countryCode: 'RU',
				latitude: 56.849999999999994,
				longitude: 60.599999999999994,
				comments: 'MSK+02 - Urals'
			},
			{
				name: 'Yerevan',
				hasChildren: false,
				fullName: 'Asia/Yerevan',
				countryCode: 'AM',
				latitude: 40.18333000000001,
				longitude: 44.5
			}
		]
	},
	{
		name: 'Atlantic',
		hasChildren: true,
		subZones: [
			{
				name: 'Azores',
				hasChildren: false,
				fullName: 'Atlantic/Azores',
				countryCode: 'PT',
				latitude: 37.733329999999995,
				longitude: -25.66667000000001,
				comments: 'Azores'
			},
			{
				name: 'Bermuda',
				hasChildren: false,
				fullName: 'Atlantic/Bermuda',
				countryCode: 'BM',
				latitude: 32.28333000000001,
				longitude: -64.76667
			},
			{
				name: 'Canary',
				hasChildren: false,
				fullName: 'Atlantic/Canary',
				countryCode: 'ES',
				latitude: 28.099999999999994,
				longitude: -15.400000000000006,
				comments: 'Canary Islands'
			},
			{
				name: 'Cape_Verde',
				displayName: 'Cape Verde',
				hasChildren: false,
				fullName: 'Atlantic/Cape_Verde',
				countryCode: 'CV',
				latitude: 14.916659999999993,
				longitude: -23.516670000000005
			},
			{
				name: 'Faroe',
				hasChildren: false,
				fullName: 'Atlantic/Faroe',
				countryCode: 'FO',
				latitude: 62.01666,
				longitude: -6.766670000000005
			},
			{
				name: 'Madeira',
				hasChildren: false,
				fullName: 'Atlantic/Madeira',
				countryCode: 'PT',
				latitude: 32.63333,
				longitude: -16.900000000000006,
				comments: 'Madeira Islands'
			},
			{
				name: 'Reykjavik',
				hasChildren: false,
				fullName: 'Atlantic/Reykjavik',
				countryCode: 'IS',
				latitude: 64.15,
				longitude: -21.849999999999994
			},
			{
				name: 'South_Georgia',
				displayName: 'South Georgia',
				hasChildren: false,
				fullName: 'Atlantic/South_Georgia',
				countryCode: 'GS',
				latitude: -54.26667,
				longitude: -36.53334000000001
			},
			{
				name: 'St_Helena',
				displayName: 'St Helena',
				hasChildren: false,
				fullName: 'Atlantic/St_Helena',
				countryCode: 'SH',
				latitude: -15.916669999999996,
				longitude: -5.699999999999989
			},
			{
				name: 'Stanley',
				hasChildren: false,
				fullName: 'Atlantic/Stanley',
				countryCode: 'FK',
				latitude: -51.70001,
				longitude: -57.849999999999994
			}
		]
	},
	{
		name: 'Australia',
		hasChildren: true,
		subZones: [
			{
				name: 'Adelaide',
				hasChildren: false,
				fullName: 'Australia/Adelaide',
				countryCode: 'AU',
				latitude: -34.91667,
				longitude: 138.58333,
				comments: 'South Australia'
			},
			{
				name: 'Brisbane',
				hasChildren: false,
				fullName: 'Australia/Brisbane',
				countryCode: 'AU',
				latitude: -27.46667,
				longitude: 153.03332999999998,
				comments: 'Queensland (most areas)'
			},
			{
				name: 'Broken_Hill',
				displayName: 'Broken Hill',
				hasChildren: false,
				fullName: 'Australia/Broken_Hill',
				countryCode: 'AU',
				latitude: -31.950000000000003,
				longitude: 141.45,
				comments: 'New South Wales (Yancowinna)'
			},
			{
				name: 'Darwin',
				hasChildren: false,
				fullName: 'Australia/Darwin',
				countryCode: 'AU',
				latitude: -12.466669999999993,
				longitude: 130.83333,
				comments: 'Northern Territory'
			},
			{
				name: 'Eucla',
				hasChildren: false,
				fullName: 'Australia/Eucla',
				countryCode: 'AU',
				latitude: -31.71667,
				longitude: 128.86666000000002,
				comments: 'Western Australia (Eucla)'
			},
			{
				name: 'Hobart',
				hasChildren: false,
				fullName: 'Australia/Hobart',
				countryCode: 'AU',
				latitude: -42.88334,
				longitude: 147.31666,
				comments: 'Tasmania'
			},
			{
				name: 'Lindeman',
				hasChildren: false,
				fullName: 'Australia/Lindeman',
				countryCode: 'AU',
				latitude: -20.266670000000005,
				longitude: 149,
				comments: 'Queensland (Whitsunday Islands)'
			},
			{
				name: 'Lord_Howe',
				displayName: 'Lord Howe',
				hasChildren: false,
				fullName: 'Australia/Lord_Howe',
				countryCode: 'AU',
				latitude: -31.549999999999997,
				longitude: 159.08333,
				comments: 'Lord Howe Island'
			},
			{
				name: 'Melbourne',
				hasChildren: false,
				fullName: 'Australia/Melbourne',
				countryCode: 'AU',
				latitude: -37.81667,
				longitude: 144.96666,
				comments: 'Victoria'
			},
			{
				name: 'Perth',
				hasChildren: false,
				fullName: 'Australia/Perth',
				countryCode: 'AU',
				latitude: -31.950000000000003,
				longitude: 115.85000000000002,
				comments: 'Western Australia (most areas)'
			},
			{
				name: 'Sydney',
				hasChildren: false,
				fullName: 'Australia/Sydney',
				countryCode: 'AU',
				latitude: -33.86667,
				longitude: 151.21666,
				comments: 'New South Wales (most areas)'
			}
		]
	},
	{
		name: 'Europe',
		hasChildren: true,
		subZones: [
			{
				name: 'Amsterdam',
				hasChildren: false,
				fullName: 'Europe/Amsterdam',
				countryCode: 'NL',
				latitude: 52.366659999999996,
				longitude: 4.900000000000006
			},
			{
				name: 'Andorra',
				hasChildren: false,
				fullName: 'Europe/Andorra',
				countryCode: 'AD',
				latitude: 42.5,
				longitude: 1.5166600000000017
			},
			{
				name: 'Astrakhan',
				hasChildren: false,
				fullName: 'Europe/Astrakhan',
				countryCode: 'RU',
				latitude: 46.349999999999994,
				longitude: 48.05000000000001,
				comments: 'MSK+01 - Astrakhan'
			},
			{
				name: 'Athens',
				hasChildren: false,
				fullName: 'Europe/Athens',
				countryCode: 'GR',
				latitude: 37.966660000000005,
				longitude: 23.71665999999999
			},
			{
				name: 'Belgrade',
				hasChildren: false,
				fullName: 'Europe/Belgrade',
				countryCode: 'RS',
				latitude: 44.83332999999999,
				longitude: 20.5
			},
			{
				name: 'Berlin',
				hasChildren: false,
				fullName: 'Europe/Berlin',
				countryCode: 'DE',
				latitude: 52.5,
				longitude: 13.366659999999996,
				comments: 'Germany (most areas)'
			},
			{
				name: 'Bratislava',
				hasChildren: false,
				fullName: 'Europe/Bratislava',
				countryCode: 'SK',
				latitude: 48.150000000000006,
				longitude: 17.116659999999996
			},
			{
				name: 'Brussels',
				hasChildren: false,
				fullName: 'Europe/Brussels',
				countryCode: 'BE',
				latitude: 50.83332999999999,
				longitude: 4.3333299999999895
			},
			{
				name: 'Bucharest',
				hasChildren: false,
				fullName: 'Europe/Bucharest',
				countryCode: 'RO',
				latitude: 44.43333000000001,
				longitude: 26.099999999999994
			},
			{
				name: 'Budapest',
				hasChildren: false,
				fullName: 'Europe/Budapest',
				countryCode: 'HU',
				latitude: 47.5,
				longitude: 19.08332999999999
			},
			{
				name: 'Busingen',
				hasChildren: false,
				fullName: 'Europe/Busingen',
				countryCode: 'DE',
				latitude: 47.699990000000014,
				longitude: 8.683330000000012,
				comments: 'Busingen'
			},
			{
				name: 'Chisinau',
				hasChildren: false,
				fullName: 'Europe/Chisinau',
				countryCode: 'MD',
				latitude: 47,
				longitude: 28.83332999999999
			},
			{
				name: 'Copenhagen',
				hasChildren: false,
				fullName: 'Europe/Copenhagen',
				countryCode: 'DK',
				latitude: 55.66666000000001,
				longitude: 12.58332999999999
			},
			{
				name: 'Dublin',
				hasChildren: false,
				fullName: 'Europe/Dublin',
				countryCode: 'IE',
				latitude: 53.33332999999999,
				longitude: -6.25
			},
			{
				name: 'Gibraltar',
				hasChildren: false,
				fullName: 'Europe/Gibraltar',
				countryCode: 'GI',
				latitude: 36.13333,
				longitude: -5.349999999999994
			},
			{
				name: 'Guernsey',
				hasChildren: false,
				fullName: 'Europe/Guernsey',
				countryCode: 'GG',
				latitude: 49.45472000000001,
				longitude: -2.536120000000011
			},
			{
				name: 'Helsinki',
				hasChildren: false,
				fullName: 'Europe/Helsinki',
				countryCode: 'FI',
				latitude: 60.16666000000001,
				longitude: 24.96665999999999
			},
			{
				name: 'Isle_of_Man',
				displayName: 'Isle of Man',
				hasChildren: false,
				fullName: 'Europe/Isle_of_Man',
				countryCode: 'IM',
				latitude: 54.150000000000006,
				longitude: -4.4666699999999935
			},
			{
				name: 'Istanbul',
				hasChildren: false,
				fullName: 'Europe/Istanbul',
				countryCode: 'TR',
				latitude: 41.01666,
				longitude: 28.96665999999999
			},
			{
				name: 'Jersey',
				hasChildren: false,
				fullName: 'Europe/Jersey',
				countryCode: 'JE',
				latitude: 49.18360999999999,
				longitude: -2.1066700000000083
			},
			{
				name: 'Kaliningrad',
				hasChildren: false,
				fullName: 'Europe/Kaliningrad',
				countryCode: 'RU',
				latitude: 54.71665999999999,
				longitude: 20.5,
				comments: 'MSK-01 - Kaliningrad'
			},
			{
				name: 'Kiev',
				hasChildren: false,
				fullName: 'Europe/Kiev',
				countryCode: 'UA',
				latitude: 50.43333000000001,
				longitude: 30.51666,
				comments: 'Ukraine (most areas)'
			},
			{
				name: 'Kirov',
				hasChildren: false,
				fullName: 'Europe/Kirov',
				countryCode: 'RU',
				latitude: 58.599999999999994,
				longitude: 49.650000000000006,
				comments: 'MSK+00 - Kirov'
			},
			{
				name: 'Lisbon',
				hasChildren: false,
				fullName: 'Europe/Lisbon',
				countryCode: 'PT',
				latitude: 38.71665999999999,
				longitude: -9.133340000000004,
				comments: 'Portugal (mainland)'
			},
			{
				name: 'Ljubljana',
				hasChildren: false,
				fullName: 'Europe/Ljubljana',
				countryCode: 'SI',
				latitude: 46.05000000000001,
				longitude: 14.516660000000002
			},
			{
				name: 'London',
				hasChildren: false,
				fullName: 'Europe/London',
				countryCode: 'GB',
				latitude: 51.50833,
				longitude: -0.1252800000000036
			},
			{
				name: 'Luxembourg',
				hasChildren: false,
				fullName: 'Europe/Luxembourg',
				countryCode: 'LU',
				latitude: 49.599999999999994,
				longitude: 6.150000000000006
			},
			{
				name: 'Madrid',
				hasChildren: false,
				fullName: 'Europe/Madrid',
				countryCode: 'ES',
				latitude: 40.400000000000006,
				longitude: -3.683339999999987,
				comments: 'Spain (mainland)'
			},
			{
				name: 'Malta',
				hasChildren: false,
				fullName: 'Europe/Malta',
				countryCode: 'MT',
				latitude: 35.900000000000006,
				longitude: 14.516660000000002
			},
			{
				name: 'Mariehamn',
				hasChildren: false,
				fullName: 'Europe/Mariehamn',
				countryCode: 'AX',
				latitude: 60.099999999999994,
				longitude: 19.94999999999999
			},
			{
				name: 'Minsk',
				hasChildren: false,
				fullName: 'Europe/Minsk',
				countryCode: 'BY',
				latitude: 53.900000000000006,
				longitude: 27.566660000000013
			},
			{
				name: 'Monaco',
				hasChildren: false,
				fullName: 'Europe/Monaco',
				countryCode: 'MC',
				latitude: 43.699990000000014,
				longitude: 7.383330000000001
			},
			{
				name: 'Moscow',
				hasChildren: false,
				fullName: 'Europe/Moscow',
				countryCode: 'RU',
				latitude: 55.75583,
				longitude: 37.61777000000001,
				comments: 'MSK+00 - Moscow area'
			},
			{
				name: 'Oslo',
				hasChildren: false,
				fullName: 'Europe/Oslo',
				countryCode: 'NO',
				latitude: 59.91666000000001,
				longitude: 10.75
			},
			{
				name: 'Paris',
				hasChildren: false,
				fullName: 'Europe/Paris',
				countryCode: 'FR',
				latitude: 48.866659999999996,
				longitude: 2.3333299999999895
			},
			{
				name: 'Podgorica',
				hasChildren: false,
				fullName: 'Europe/Podgorica',
				countryCode: 'ME',
				latitude: 42.43333000000001,
				longitude: 19.26666
			},
			{
				name: 'Prague',
				hasChildren: false,
				fullName: 'Europe/Prague',
				countryCode: 'CZ',
				latitude: 50.08332999999999,
				longitude: 14.433330000000012
			},
			{
				name: 'Riga',
				hasChildren: false,
				fullName: 'Europe/Riga',
				countryCode: 'LV',
				latitude: 56.949990000000014,
				longitude: 24.099999999999994
			},
			{
				name: 'Rome',
				hasChildren: false,
				fullName: 'Europe/Rome',
				countryCode: 'IT',
				latitude: 41.900000000000006,
				longitude: 12.483329999999995
			},
			{
				name: 'Samara',
				hasChildren: false,
				fullName: 'Europe/Samara',
				countryCode: 'RU',
				latitude: 53.199990000000014,
				longitude: 50.150000000000006,
				comments: 'MSK+01 - Samara, Udmurtia'
			},
			{
				name: 'San_Marino',
				displayName: 'San Marino',
				hasChildren: false,
				fullName: 'Europe/San_Marino',
				countryCode: 'SM',
				latitude: 43.91666000000001,
				longitude: 12.46665999999999
			},
			{
				name: 'Sarajevo',
				hasChildren: false,
				fullName: 'Europe/Sarajevo',
				countryCode: 'BA',
				latitude: 43.866659999999996,
				longitude: 18.416660000000007
			},
			{
				name: 'Saratov',
				hasChildren: false,
				fullName: 'Europe/Saratov',
				countryCode: 'RU',
				latitude: 51.56666000000001,
				longitude: 46.03333000000001,
				comments: 'MSK+01 - Saratov'
			},
			{
				name: 'Simferopol',
				hasChildren: false,
				fullName: 'Europe/Simferopol',
				countryCode: 'UA',
				latitude: 44.949990000000014,
				longitude: 34.099999999999994,
				comments: 'Crimea'
			},
			{
				name: 'Skopje',
				hasChildren: false,
				fullName: 'Europe/Skopje',
				countryCode: 'MK',
				latitude: 41.983329999999995,
				longitude: 21.433330000000012
			},
			{
				name: 'Sofia',
				hasChildren: false,
				fullName: 'Europe/Sofia',
				countryCode: 'BG',
				latitude: 42.68333000000001,
				longitude: 23.316660000000013
			},
			{
				name: 'Stockholm',
				hasChildren: false,
				fullName: 'Europe/Stockholm',
				countryCode: 'SE',
				latitude: 59.33332999999999,
				longitude: 18.05000000000001
			},
			{
				name: 'Tallinn',
				hasChildren: false,
				fullName: 'Europe/Tallinn',
				countryCode: 'EE',
				latitude: 59.41666000000001,
				longitude: 24.75
			},
			{
				name: 'Tirane',
				hasChildren: false,
				fullName: 'Europe/Tirane',
				countryCode: 'AL',
				latitude: 41.33332999999999,
				longitude: 19.83332999999999
			},
			{
				name: 'Ulyanovsk',
				hasChildren: false,
				fullName: 'Europe/Ulyanovsk',
				countryCode: 'RU',
				latitude: 54.33332999999999,
				longitude: 48.400000000000006,
				comments: 'MSK+01 - Ulyanovsk'
			},
			{
				name: 'Uzhgorod',
				hasChildren: false,
				fullName: 'Europe/Uzhgorod',
				countryCode: 'UA',
				latitude: 48.616659999999996,
				longitude: 22.30000000000001,
				comments: 'Transcarpathia'
			},
			{
				name: 'Vaduz',
				hasChildren: false,
				fullName: 'Europe/Vaduz',
				countryCode: 'LI',
				latitude: 47.150000000000006,
				longitude: 9.516660000000002
			},
			{
				name: 'Vatican',
				hasChildren: false,
				fullName: 'Europe/Vatican',
				countryCode: 'VA',
				latitude: 41.90222,
				longitude: 12.45304999999999
			},
			{
				name: 'Vienna',
				hasChildren: false,
				fullName: 'Europe/Vienna',
				countryCode: 'AT',
				latitude: 48.21665999999999,
				longitude: 16.33332999999999
			},
			{
				name: 'Vilnius',
				hasChildren: false,
				fullName: 'Europe/Vilnius',
				countryCode: 'LT',
				latitude: 54.68333000000001,
				longitude: 25.316660000000013
			},
			{
				name: 'Volgograd',
				hasChildren: false,
				fullName: 'Europe/Volgograd',
				countryCode: 'RU',
				latitude: 48.733329999999995,
				longitude: 44.41666000000001,
				comments: 'MSK+00 - Volgograd'
			},
			{
				name: 'Warsaw',
				hasChildren: false,
				fullName: 'Europe/Warsaw',
				countryCode: 'PL',
				latitude: 52.25,
				longitude: 21
			},
			{
				name: 'Zagreb',
				hasChildren: false,
				fullName: 'Europe/Zagreb',
				countryCode: 'HR',
				latitude: 45.80000000000001,
				longitude: 15.96665999999999
			},
			{
				name: 'Zaporozhye',
				hasChildren: false,
				fullName: 'Europe/Zaporozhye',
				countryCode: 'UA',
				latitude: 47.83332999999999,
				longitude: 35.16666000000001,
				comments: 'Zaporozhye and east Lugansk'
			},
			{
				name: 'Zurich',
				hasChildren: false,
				fullName: 'Europe/Zurich',
				countryCode: 'CH',
				latitude: 47.38333,
				longitude: 8.533330000000007
			}
		]
	},
	{
		name: 'Indian',
		hasChildren: true,
		subZones: [
			{
				name: 'Antananarivo',
				hasChildren: false,
				fullName: 'Indian/Antananarivo',
				countryCode: 'MG',
				latitude: -18.916669999999996,
				longitude: 47.51666
			},
			{
				name: 'Chagos',
				hasChildren: false,
				fullName: 'Indian/Chagos',
				countryCode: 'IO',
				latitude: -7.333340000000007,
				longitude: 72.41666000000001
			},
			{
				name: 'Christmas',
				hasChildren: false,
				fullName: 'Indian/Christmas',
				countryCode: 'CX',
				latitude: -10.416669999999996,
				longitude: 105.71665999999999
			},
			{
				name: 'Cocos',
				hasChildren: false,
				fullName: 'Indian/Cocos',
				countryCode: 'CC',
				latitude: -12.166669999999996,
				longitude: 96.91665999999998
			},
			{
				name: 'Comoro',
				hasChildren: false,
				fullName: 'Indian/Comoro',
				countryCode: 'KM',
				latitude: -11.683340000000001,
				longitude: 43.26666
			},
			{
				name: 'Kerguelen',
				hasChildren: false,
				fullName: 'Indian/Kerguelen',
				countryCode: 'TF',
				latitude: -49.35278,
				longitude: 70.2175
			},
			{
				name: 'Mahe',
				hasChildren: false,
				fullName: 'Indian/Mahe',
				countryCode: 'SC',
				latitude: -4.666669999999996,
				longitude: 55.46665999999999
			},
			{
				name: 'Maldives',
				hasChildren: false,
				fullName: 'Indian/Maldives',
				countryCode: 'MV',
				latitude: 4.166659999999993,
				longitude: 73.5
			},
			{
				name: 'Mauritius',
				hasChildren: false,
				fullName: 'Indian/Mauritius',
				countryCode: 'MU',
				latitude: -20.166669999999996,
				longitude: 57.5
			},
			{
				name: 'Mayotte',
				hasChildren: false,
				fullName: 'Indian/Mayotte',
				countryCode: 'YT',
				latitude: -12.783339999999995,
				longitude: 45.233329999999995
			},
			{
				name: 'Reunion',
				hasChildren: false,
				fullName: 'Indian/Reunion',
				countryCode: 'RE',
				latitude: -20.86667,
				longitude: 55.46665999999999
			}
		]
	},
	{
		name: 'Pacific',
		hasChildren: true,
		subZones: [
			{
				name: 'Apia',
				hasChildren: false,
				fullName: 'Pacific/Apia',
				countryCode: 'WS',
				latitude: -13.833340000000007,
				longitude: -171.73334
			},
			{
				name: 'Auckland',
				hasChildren: false,
				fullName: 'Pacific/Auckland',
				countryCode: 'NZ',
				latitude: -36.86667,
				longitude: 174.76666,
				comments: 'New Zealand (most areas)'
			},
			{
				name: 'Bougainville',
				hasChildren: false,
				fullName: 'Pacific/Bougainville',
				countryCode: 'PG',
				latitude: -6.2166699999999935,
				longitude: 155.56666,
				comments: 'Bougainville'
			},
			{
				name: 'Chatham',
				hasChildren: false,
				fullName: 'Pacific/Chatham',
				countryCode: 'NZ',
				latitude: -43.95,
				longitude: -176.55001,
				comments: 'Chatham Islands'
			},
			{
				name: 'Chuuk',
				hasChildren: false,
				fullName: 'Pacific/Chuuk',
				countryCode: 'FM',
				latitude: 7.416659999999993,
				longitude: 151.78332999999998,
				comments: 'Chuuk/Truk, Yap'
			},
			{
				name: 'Easter',
				hasChildren: false,
				fullName: 'Pacific/Easter',
				countryCode: 'CL',
				latitude: -27.15,
				longitude: -109.43334,
				comments: 'Easter Island'
			},
			{
				name: 'Efate',
				hasChildren: false,
				fullName: 'Pacific/Efate',
				countryCode: 'VU',
				latitude: -17.666669999999996,
				longitude: 168.41665999999998
			},
			{
				name: 'Fakaofo',
				hasChildren: false,
				fullName: 'Pacific/Fakaofo',
				countryCode: 'TK',
				latitude: -9.36667,
				longitude: -171.23334
			},
			{
				name: 'Fiji',
				hasChildren: false,
				fullName: 'Pacific/Fiji',
				countryCode: 'FJ',
				latitude: -18.133340000000004,
				longitude: 178.41665999999998
			},
			{
				name: 'Funafuti',
				hasChildren: false,
				fullName: 'Pacific/Funafuti',
				countryCode: 'TV',
				latitude: -8.516670000000005,
				longitude: 179.21666
			},
			{
				name: 'Galapagos',
				hasChildren: false,
				fullName: 'Pacific/Galapagos',
				countryCode: 'EC',
				latitude: -0.9000000000000057,
				longitude: -89.6,
				comments: 'Galapagos Islands'
			},
			{
				name: 'Gambier',
				hasChildren: false,
				fullName: 'Pacific/Gambier',
				countryCode: 'PF',
				latitude: -23.133340000000004,
				longitude: -134.95,
				comments: 'Gambier Islands'
			},
			{
				name: 'Guadalcanal',
				hasChildren: false,
				fullName: 'Pacific/Guadalcanal',
				countryCode: 'SB',
				latitude: -9.533339999999995,
				longitude: 160.2
			},
			{
				name: 'Guam',
				hasChildren: false,
				fullName: 'Pacific/Guam',
				countryCode: 'GU',
				latitude: 13.466660000000005,
				longitude: 144.75
			},
			{
				name: 'Honolulu',
				hasChildren: false,
				fullName: 'Pacific/Honolulu',
				countryCode: 'US',
				latitude: 21.306939999999997,
				longitude: -157.85834,
				comments: 'Hawaii'
			},
			{
				name: 'Kanton',
				hasChildren: false,
				fullName: 'Pacific/Kanton',
				countryCode: 'KI',
				latitude: -2.7833399999999955,
				longitude: -171.71667,
				comments: 'Phoenix Islands'
			},
			{
				name: 'Kiritimati',
				hasChildren: false,
				fullName: 'Pacific/Kiritimati',
				countryCode: 'KI',
				latitude: 1.866659999999996,
				longitude: -157.33334,
				comments: 'Line Islands'
			},
			{
				name: 'Kosrae',
				hasChildren: false,
				fullName: 'Pacific/Kosrae',
				countryCode: 'FM',
				latitude: 5.316659999999999,
				longitude: 162.98333000000002,
				comments: 'Kosrae'
			},
			{
				name: 'Kwajalein',
				hasChildren: false,
				fullName: 'Pacific/Kwajalein',
				countryCode: 'MH',
				latitude: 9.083330000000004,
				longitude: 167.33333,
				comments: 'Kwajalein'
			},
			{
				name: 'Majuro',
				hasChildren: false,
				fullName: 'Pacific/Majuro',
				countryCode: 'MH',
				latitude: 7.150000000000006,
				longitude: 171.2,
				comments: 'Marshall Islands (most areas)'
			},
			{
				name: 'Marquesas',
				hasChildren: false,
				fullName: 'Pacific/Marquesas',
				countryCode: 'PF',
				latitude: -9,
				longitude: -139.5,
				comments: 'Marquesas Islands'
			},
			{
				name: 'Midway',
				hasChildren: false,
				fullName: 'Pacific/Midway',
				countryCode: 'UM',
				latitude: 28.216660000000005,
				longitude: -177.36667,
				comments: 'Midway Islands'
			},
			{
				name: 'Nauru',
				hasChildren: false,
				fullName: 'Pacific/Nauru',
				countryCode: 'NR',
				latitude: -0.5166700000000048,
				longitude: 166.91665999999998
			},
			{
				name: 'Niue',
				hasChildren: false,
				fullName: 'Pacific/Niue',
				countryCode: 'NU',
				latitude: -19.016670000000005,
				longitude: -169.91667
			},
			{
				name: 'Norfolk',
				hasChildren: false,
				fullName: 'Pacific/Norfolk',
				countryCode: 'NF',
				latitude: -29.049999999999997,
				longitude: 167.96666
			},
			{
				name: 'Noumea',
				hasChildren: false,
				fullName: 'Pacific/Noumea',
				countryCode: 'NC',
				latitude: -22.266670000000005,
				longitude: 166.45
			},
			{
				name: 'Pago_Pago',
				displayName: 'Pago Pago',
				hasChildren: false,
				fullName: 'Pacific/Pago_Pago',
				countryCode: 'AS',
				latitude: -14.266670000000005,
				longitude: -170.7
			},
			{
				name: 'Palau',
				hasChildren: false,
				fullName: 'Pacific/Palau',
				countryCode: 'PW',
				latitude: 7.333330000000004,
				longitude: 134.48333000000002
			},
			{
				name: 'Pitcairn',
				hasChildren: false,
				fullName: 'Pacific/Pitcairn',
				countryCode: 'PN',
				latitude: -25.066670000000002,
				longitude: -130.08334
			},
			{
				name: 'Pohnpei',
				hasChildren: false,
				fullName: 'Pacific/Pohnpei',
				countryCode: 'FM',
				latitude: 6.9666600000000045,
				longitude: 158.21666,
				comments: 'Pohnpei/Ponape'
			},
			{
				name: 'Port_Moresby',
				displayName: 'Port Moresby',
				hasChildren: false,
				fullName: 'Pacific/Port_Moresby',
				countryCode: 'PG',
				latitude: -9.5,
				longitude: 147.16665999999998,
				comments: 'Papua New Guinea (most areas)'
			},
			{
				name: 'Rarotonga',
				hasChildren: false,
				fullName: 'Pacific/Rarotonga',
				countryCode: 'CK',
				latitude: -21.23334,
				longitude: -159.76667
			},
			{
				name: 'Saipan',
				hasChildren: false,
				fullName: 'Pacific/Saipan',
				countryCode: 'MP',
				latitude: 15.200000000000003,
				longitude: 145.75
			},
			{
				name: 'Tahiti',
				hasChildren: false,
				fullName: 'Pacific/Tahiti',
				countryCode: 'PF',
				latitude: -17.533339999999995,
				longitude: -149.56667,
				comments: 'Society Islands'
			},
			{
				name: 'Tarawa',
				hasChildren: false,
				fullName: 'Pacific/Tarawa',
				countryCode: 'KI',
				latitude: 1.4166599999999931,
				longitude: 173,
				comments: 'Gilbert Islands'
			},
			{
				name: 'Tongatapu',
				hasChildren: false,
				fullName: 'Pacific/Tongatapu',
				countryCode: 'TO',
				latitude: -21.133340000000004,
				longitude: -175.2
			},
			{
				name: 'Wake',
				hasChildren: false,
				fullName: 'Pacific/Wake',
				countryCode: 'UM',
				latitude: 19.283330000000007,
				longitude: 166.61666000000002,
				comments: 'Wake Island'
			},
			{
				name: 'Wallis',
				hasChildren: false,
				fullName: 'Pacific/Wallis',
				countryCode: 'WF',
				latitude: -13.299999999999997,
				longitude: -176.16667
			}
		]
	},
	{
		name: 'UTC',
		hasChildren: false,
		fullName: 'UTC',
		countryCode: '??',
		latitude: -90,
		longitude: -180
	}
];
